import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from './reducer'

import configureAppStore from "./configureStore";

let preloadedState = {authenticated: false, navBarsDM: {top: false, bottom: true}, navBarsSelectedSection: 'Home' ,profile: null}

const persistConfig = {
    key: 'p',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
 
const store = configureAppStore(preloadedState,persistedReducer);

const persistor = persistStore(store);

export {store as default,persistor};