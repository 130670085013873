import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Container, IconButton, Input, Typography } from '@mui/material';
import formatNumberToAbbString from '../../../../../../helpers/formatNumberToAbbString';
import {
  adjustWidth,
  convertTextToPositive,
  numberWithCommas,
} from '../../../../../../helpers/inputAdjustment';
import { useRef } from 'react';
// import getCursorPosition from '../../../../../../helpers/getCursorPosition';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
  },
  slider: {
    color: '#1e1e1e',
    width: '580px',
    height: '12px',

    border: '1px solid white',
    borderRadius: '1010px',
    padding: 0,
    '& .MuiSlider-thumb': {
      color: '#ffbf12',
      height: '23px',
      width: '23px',
      '&:hover': {
        boxShadow: '0 0 0 0.2rem rgb(255 191 18 / 44%)', // Adjust halo color as needed
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)', // Adjust halo color as needed
      },
    },
  },
}));

const Slider_desktop = (props) => {
  const classes = useStyles();
  const [sliderValue, setSliderValue] = useState(0);
  const [inputValue, setInputValue] = useState('0');
  const [position, setposition] = useState(0);
  const [cursorPosition, setcursorPosition] = useState({ previousValue: 0, position: 0 });
  const widthUnit = 100 / 61;

  const inputRef = useRef(null);

  const handleSliderChange = (event, newValue) => {
    setposition(widthUnit * sliderValue);
    // calc followers:
    let followers;
    if (newValue === 0) {
      followers = 0;
    } else {
      followers =
        Math.pow(10, Math.floor(newValue / 10)) * 1000 * (newValue % 10 === 0 ? 1 : newValue % 10);
    }
    setInputValue(numberWithCommas(followers));
    adjustWidth(followers * 10, inputRef.current);
    props.setFollowers(followers);
    let tempSliderValue;
    if (newValue === 0) {
      tempSliderValue = 0;
    } else {
      tempSliderValue = newValue % 10 === 0 ? newValue + 1 : newValue;
    }
    setSliderValue(tempSliderValue);
  };

  const handleInputChange = (event) => {
    let newValue = convertTextToPositive(event.target.value);
    // const startPosition = event.target.selectionStart;
    // const endPosition = event.target.selectionEnd;

    // const newPosition =
    //   cursorPosition.position + (event.target.value.length - cursorPosition.length);
    // console.log('new ' + newPosition);
    // setcursorPosition({ length: newValue.length, position: newPosition });
    adjustWidth(newValue * 10, inputRef.current);
    setInputValue(numberWithCommas(newValue));
    // event.target.setSelectionRange(cursorPosition.position, cursorPosition.position);
    // setSliderValue(newValue);
    props.setFollowers(+newValue);
    // console.log(event.target.value);
    // calc the step on slider
    let pow = calculateNumberOfDoublesToRoundDown(newValue);
    let stepDigitUnit = Math.floor(newValue / Math.pow(10, pow));
    let step = (pow - 3) * 10 + stepDigitUnit;
    // console.log(step + ',' + pow + ',' + stepDigitUnit);
    step = step > 62 ? 62 : step;
    step = step < 0 ? 0 : step;
    setSliderValue(step);
    setposition(widthUnit * step);
  };

  function calculateNumberOfDoublesToRoundDown(number) {
    // Calculate the base-2 logarithm of the number
    const log2Number = Math.log2(number);

    // Calculate the base-2 logarithm of 10
    const log2Ten = Math.log2(10);

    // Divide the logarithm of the number by the logarithm of 1000
    const numberOfDoubles = log2Number / log2Ten;

    // Round down to the nearest integer
    const roundedDown = Math.floor(numberOfDoubles);

    return roundedDown;
  }
  let worth = props.calcBrand(Number(inputValue.replace(/,/g, ''))).worth;
  worth = {
    min: `$${formatNumberToAbbString(worth.min)}`,
    max: `$${formatNumberToAbbString(worth.max)}`,
  };
  return (
    <Container sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <IconButton style={{ width: '52px', padding: props.padding }}>
        <img src={props.icon} alt="props.alt" style={{ width: props.width }} />
      </IconButton>
      <Container
        sx={{
          position: 'relative',
          width: 'fit-content',
          height: 90,
          padding: '0 !important',
          margin: '0',
        }}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            top: 0,
            left: `${position - 1}%`,
            padding: '0 !important',
            textAlign: 'center !important',
            height: '40px',
            width: props.youtube ? '222px' : '190px',
            margin: '0',
          }}
        >
          {' '}
          <Input
            ref={inputRef}
            sx={{
              border: '1px solid #707070',
              margin: '10px 0',
              padding: '5px',
              height: '30px',
              color: '#ffbf12',
              borderRadius: '10px',
              width: '23px',
              textAlign: 'right',
              appearance: 'none',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
            }}
            value={inputValue}
            onChange={handleInputChange}
            onSelectCapture={(e) => {
              if (cursorPosition.previousValue !== e.target.value && e.target.value !== '0') {
                if (e.target.value.length !== cursorPosition.previousValue.length) {
                  e.target.selectionStart =
                    cursorPosition.position +
                    (e.target.value.length - cursorPosition.previousValue.length);
                  e.target.selectionEnd =
                    cursorPosition.position +
                    (e.target.value.length - cursorPosition.previousValue.length);
                } else {
                  e.target.selectionStart = cursorPosition.position - 1;
                  e.target.selectionEnd = cursorPosition.position - 1;
                }
              }
              let temp = { previousValue: e.target.value, position: e.target.selectionStart };
              setcursorPosition(temp);
            }}
            type="text"
          />
          <Typography
            className="headline"
            style={{ fontSize: '16px', color: 'white', paddingLeft: '8px' }}
          >
            {props.youtube ? 'subscribers' : 'followers'}
          </Typography>
        </Container>
        <Slider
          className={classes.slider}
          // defaultValue={10}
          value={sliderValue}
          step={1}
          marks
          min={0}
          max={62}
          track={false}
          onChange={handleSliderChange}
        />
      </Container>
      <p
        className="headline"
        style={{
          fontWeight: 'lighter',
          padding: '10px 0px 0',
          width: '140px',
          textAlign: 'center',
          fontSize: '18px',
          lineHeight: '20px',
          color:'#707070'
        }}
      >
        ~{worth?.min} - {worth?.max}
      </p>
    </Container>
  );
};

export default Slider_desktop;
