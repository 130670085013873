
export default function latestVersionSaved(state = { desktopSite: '0.0.0', mobileSite: '0.0.0' }, action) {
    switch (action.type) {
        case 'app/latestVersionSaved/add': {
            return action.payload
        }
        case 'app/latestVersionSaved/delete': {
            return { desktopSite: '0.0.0', mobileSite: '0.0.0' }
        }

        default:
            return state
    }
}