export default function isMobileDevice() {
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        // "Android",
        "webOS",
        "iPhone",
        // "iPad",
        "iPod",
        "BlackBerry",
        "Windows Phone"
    ];

    return mobileKeywords.some(keyword => userAgent.includes(keyword));
}