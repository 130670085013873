// false - [] , {} , null , false
export default function isVarNegative(variable) {
    if (!variable) {
        return variable;
    }
    if (Array.isArray(variable)) {
        if (variable.length === 0) {
            return false;
        }
    }
    if (typeof variable === 'object') {
        if (Object.keys(variable).length === 0
            && Object.getPrototypeOf(variable) === Object.prototype) {
            return false;
        }
    }

    return true;
}