import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store/store';
// import "../desktop/home/style.css";
import './style.css';
import '../../styles/app-style.css';
import '../../styles/XD_home.css';
// import store from "../../store/store.js";

import LongLogo from '../../images/ps-logo-one-row.png';
import YoutubeLogoBW from '../../images/YoutubeLogoBW.png';
import InstagramLogoBW from '../../images/InstagramLogoBW.png';
import FacebookLogoBW from '../../images/FacebookLogoBW.png';
import PSLogo from '../../images/PassionStocksIcon.png';
import PSLBckg from '../../images/PSLBckg.png';
import PSLogoYellow from '../../images/ps-icon.png';
import StarsEyesEmoji from '../../images/emoji-stars-eyes.png';
import Done from '../../images/confirm.png';
import ArrowDown from '../../images/arrow-down-btn.png';
import DoubleArrowDown from '../../images/double-arrow-down-white.png';
import WhiteArrowDown from '../../images/arrow-down-white-btn.png';

import {
  Checkbox,
  IconButton,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Button,
  Alert,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  InputBase,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import isVarNegative from '../../tools/isVarNegative';
import EstimateSlide from './slides/Estimate';
import GlowingScrollSign from './components/GlowingScrollSign';
import { scrollRestOfSlide } from './utiles';
import isMobileDevice from '../../utiles/isMobileDevice';
import WelcomeSlide from './slides/Welcome';
// const selectWindowInnerSize = (state) => state.windowInnerSize;

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectWaitlistSelect = (state) => state.waitlistSelect;

const LandingPage = () => {
  const windowInnerSize = useSelector(selectWindowInnerSize);
  useEffect(() => {
    if (!windowInnerSize) {
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: null,
      });
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: { height: window.outerHeight, width: window.outerWidth },
      });
    }
  }, [windowInnerSize]);

  // navigate to the last position:
  const location = useLocation();

  useEffect(() => {
    const scrollPositionUp = document.getElementById('LandingPage');
    if (location.state?.from === 'wishlist') {
      scrollPositionUp.scrollTo({
        top: windowInnerSize.height * 19,
      });
    }
    if (location.state?.from === 'footer') {
      scrollPositionUp.scrollTo({
        top: windowInnerSize.height * 21,
      });
    }
  }, [location]);

  // Snackbar display wehn submit waiting list form:
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // waiting list form handle
  const roles = ['creator', 'manager', 'business', 'investor', 'other'];
  const [selectedCheckbox, setSelectedCheckbox] = useState('Select');
  const [userEmail, setUserEmail] = useState('');
  const [isTsAndCsChecked, setisTsAndCsChecked] = useState(null);
  const [isPSContactChecked, setisPSContactChecked] = useState(null);

  const handleSelectChange = (event) => {
    store.dispatch({
      type: 'ui/waitlistSelect/add',
      payload: null,
    });
    setSelectedCheckbox(event.target.value);
  };
  const handleSubmit = () => {
    if (
      userEmail &&
      userEmail !== '' &&
      selectedCheckbox !== null &&
      selectedCheckbox !== '' &&
      selectedCheckbox !== 'Select' &&
      isTsAndCsChecked &&
      isPSContactChecked
    ) {
      var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + '/api/users/waiting-list',
        params: { email: userEmail, role: selectedCheckbox },
      };

      axios(config)
        .then(function (response) {
          setOpen('success');
          setMessage('Your request has been successfully received');
        })
        .catch(function (error) {
          if (error.response?.data?.message) {
            setOpen('info');
            setMessage(error.response.data.message);
          } else {
            setOpen('error');
            setMessage('There is a problem, please try again later');
          }
        });
      setTimeout(() => {
        setOpen(null);
      }, 6000);
    } else {
      if (userEmail === null) {
        setOpen('info');
        setMessage('Please enter a valid email address');
      } else {
        setOpen('info');
        setMessage('Please complete missing details');
      }
    }
  };

  const pageRef = useRef(null);
  const arrowRef = useRef(null);
  const doubleArrowRef = useRef(null);
  const whiteArrowRef = useRef(null);
  const welcomeSlideRef = useRef(null);
  const estimateSlideRef = useRef(null);
  const openingSlideRef = useRef(null);
  const slide1Ref = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);
  const slide2Ref = useRef(null);
  const text1S2 = useRef(null);
  const text2S2 = useRef(null);
  const text3S2 = useRef(null);
  const text4S2 = useRef(null);
  const slide3Ref = useRef(null);
  const text1S3 = useRef(null);
  const text2S3 = useRef(null);
  const text3S3 = useRef(null);
  const text4S3 = useRef(null);
  const text5S3 = useRef(null);
  const slide4Ref = useRef(null);
  const text1S4 = useRef(null);
  const text2S4 = useRef(null);
  const text3S4 = useRef(null);
  const slide5Ref = useRef(null);
  const slide6Ref = useRef(null);

  const [text3s3StyleIndicator, setText3s3StyleIndicator] = useState(null);

  const [scrolling, setscrolling] = useState(true);
  useEffect(() => {
    if (isVarNegative(windowInnerSize)) {
      const pageElement = pageRef.current;
      const arrowElement = arrowRef.current;
      const doubleArrowElement = doubleArrowRef.current;
      const whiteArrowElement = whiteArrowRef.current;
      const welcomeSlideElement = welcomeSlideRef.current;
      const estimateSlideElement = estimateSlideRef.current;
      const openingSlideElement = openingSlideRef.current;
      const slide1Element = slide1Ref.current;
      const text1Element = text1.current;
      const text2Element = text2.current;
      const text3Element = text3.current;
      //
      const slide2Element = slide2Ref.current;
      const text1S2Element = text1S2.current;
      const text2S2Element = text2S2.current;
      const text3S2Element = text3S2.current;
      const text4S2Element = text4S2.current;
      //
      const slide3Element = slide3Ref.current;
      const text1S3Element = text1S3.current;
      const text2S3Element = text2S3.current;
      const text3S3Element = text3S3.current;
      const text4S3Element = text4S3.current;
      const text5S3Element = text5S3.current;
      //
      const slide4Element = slide4Ref.current;
      const text1S4Element = text1S4.current;
      const text2S4Element = text2S4.current;
      const text3S4Element = text3S4.current;
      //
      const slide5Element = slide5Ref.current;
      //
      const slide6Element = slide6Ref.current;

      function debounce(func, delay) {
        let timeout;
        return function () {
          const context = this;
          const args = arguments;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), delay);
        };
      }

      var handleScroll = debounce(() => {
        if (true) {
          if (windowInnerSize.height !== window.innerHeight) {
            store.dispatch({
              type: 'ui/windowInnerSize/add',
              payload: null,
            });
            store.dispatch({
              type: 'ui/windowInnerSize/add',
              payload: { height: window.innerHeight, width: window.innerWidth },
            });

            return;
          }
          const element = document.getElementById('LandingPage');

          const scrollPosition = pageElement.scrollTop;
          const gap = estimateSlideElement.offsetHeight + welcomeSlideElement.offsetHeight;

          if (scrollPosition < gap + windowInnerSize.height * 0.3) {
            setarrowDirection('down');
          }
          if (
            scrollPosition >= gap + windowInnerSize.height * 0.15 &&
            scrollPosition < element.scrollHeight - windowInnerSize.height
          ) {
            setdisplayArrow(true);
            setdisplayWhiteArrow(false);
          }
          // scroll into slide 4:
          if (scrollPosition < gap + windowInnerSize.height * 3.5) {
            setdisplayWhiteArrow(false);
          }
          // scroll into waitlist slide:
          if (
            scrollPosition > gap + windowInnerSize.height * 3.5 &&
            scrollPosition < element.scrollHeight - windowInnerSize.height
          ) {
            setdisplayArrow(true);
            setarrowDirection('up');
            setdisplayWhiteArrow(true);
          }
          if (scrollPosition > element.scrollHeight - windowInnerSize.height * 2) {
            // setdisplayArrow(false);
            setdisplayWhiteArrow(false);
          }

          if (scrollPosition > gap + windowInnerSize.height * 0.15) {
            //slide 1
            // else
            text1Element.style.opacity = 1;

            setTimeout(() => {
              text2Element.style.opacity = 1;
            }, 1200);
            setTimeout(() => {
              text3Element.style.opacity = 1;
            }, 2400);
            // }
          }
          // Slide2
          // else {
          if (scrollPosition > gap + windowInnerSize.height * 0.95 /*(6.35 + factor)*/) {
            text1S2Element.style.opacity = 1;
            text4S2Element.style.opacity = 1;
            setTimeout(() => {
              text2S2Element.style.opacity = 1;
            }, 1200);
            setTimeout(() => {
              text3S2Element.style.opacity = 1;
            }, 2400);
            // }
          }
          // slide 3 dddd
          // else {
          if (scrollPosition > gap + windowInnerSize.height * 1.75) {
            text1S3Element.style.opacity = 1;
            text2S3Element.style.opacity = 1;
            setTimeout(() => {
              let ee = document.getElementById('oo');
              ee.textContent = 'r';
              ee.style.opacity = 1;
              text3S3Element.style.opacity = 1;
              setTimeout(() => {
                setText3s3StyleIndicator(true);
              }, 400);
            }, 1200);
            setTimeout(() => {
              text4S3Element.style.opacity = 1;
            }, 2400);
            setTimeout(() => {
              text5S3Element.style.opacity = 1;
            }, 3600);
            // }
          }
          // slide 4
          // else {
          if (scrollPosition > gap + windowInnerSize.height * 2.55) {
            text1S4Element.style.opacity = 1;
            setTimeout(() => {
              text2S4Element.style.opacity = 1;
            }, 1200);
            setTimeout(() => {
              text3S4Element.style.opacity = 1;
            }, 2400);
            // }
          }
          // slide 5 - waitlist
          if (scrollPosition > gap + windowInnerSize.height * 3.25) {
            // arrowElement.style.left = '2em';
            // arrowElement.style.marginLeft = 0;

            slide5Element.style.opacity = 1;
          } else {
            slide6Element.style.opacity = 1;
          }
        }
      }, 20);

      pageElement.addEventListener('scroll', handleScroll);
      const handleResize = () => {
        pageElement.scrollTo(0, 0);
      };
      window.addEventListener('resize', handleResize);
      const handleTouchStart = (e) => {
        // e.preventDefault();
      };
      pageElement.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });

      return () => {
        pageElement.removeEventListener('scroll', handleScroll);
        pageElement.removeEventListener('resize', handleResize);
      };
    }
  }, [windowInnerSize]);

  useEffect(() => {}, []);

  // const triggerNative = (el, eventName) => {
  //   const event = document.createEvent('HTMLEvents');
  //   // event.initEvent()
  //   const e = new Event(eventName, { bubbles: true, cancelable: false });
  //   el.dispatchEvent(e);
  // };

  //
  useEffect(() => {
    setTimeout(() => {
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: { height: window.innerHeight, width: window.innerWidth },
      });
    }, 1000);
  }, []);

  const [isPhone, setisPhone] = useState(null);
  useEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const PhoneScreen = vw < 600;
    console.log(PhoneScreen + ',' + isMobileDevice());

    setisPhone(PhoneScreen || isMobileDevice());
  }, [windowInnerSize]);

  // set Arrow apearance:
  // ===================================
  const [displayArrow, setdisplayArrow] = useState(false);
  const [arrowDirection, setarrowDirection] = useState('down');
  const [displayWhiteArrow, setdisplayWhiteArrow] = useState(false);

  const [slogenWidth, setSlogenWidth] = useState(null);
  useEffect(() => {
    const element = document.getElementById('LandingPage__TurnTxt');
    if (element) {
      const width = element.offsetWidth;
      setSlogenWidth(width);
    }
  }, [windowInnerSize]);

  // Function to debounce scroll events effects
  function debounceEffects(func, delay) {
    let timerId;
    return function () {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, arguments);
      }, delay);
    };
  }

  const waitlistSelect = useSelector(selectWaitlistSelect);

  return (
    windowInnerSize && (
      <div ref={pageRef} id="LandingPage" className="LandingPage">
        <div style={{ height: 0 /*22 * windowInnerSize.height*/ }}>
          <div
            className="LandingPage__OpeningSlide"
            ref={openingSlideRef}
            style={{
              // opacity: '0',
              position: 'relative',
              top: '0',
              left: '0',
              padding: '0',
              height: windowInnerSize.height,
              zIndex: 0,
            }}
          >
            {
              <div
                className="LandingPage__OpeningSlideContainer"
                style={{
                  width: '100vw',
                  height: windowInnerSize.height,
                }}
              >
                <div className="LandingPage__OpeningSlideText unnamed-character-style-1">
                  <div className="LandingPage__OpeningSlideTextContainer">
                    <img src={PSLogoYellow} alt="" className="LandingPage__ShortLogo" />
                    <p className="LandingPage__OpeningSlideLogoText">PassionStocks</p>
                  </div>
                  <p
                    style={{ fontSize: isPhone ? '0.64em' : '1.1em' }}
                    className="LandingPage__WhereTxt unnamed-character-style-1"
                    id="LandingPage__TurnTxt"
                  >
                    Turn your socials into stocks{' '}
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      margin: '0.2em auto 0.3em',
                      fontSize: isPhone ? '0.55em' : '0.7em',
                      lineHeight: isPhone ? '1.2em' : '1.1em',
                      textAlign: 'center',
                      opacity: 0.35,
                      width: slogenWidth,
                    }}
                  >
                    Your personal brand value is born here and raised on the stock exchange{' '}
                  </p>
                </div>
              </div>
            }
          </div>{' '}
          <div ref={welcomeSlideRef} id="EstimateSlide">
            <WelcomeSlide isPhone={isPhone} />
          </div>
          <div ref={estimateSlideRef} id="EstimateSlide">
            <EstimateSlide isPhone={isPhone} />
          </div>
          {/* <div id="LandingPage__ScrollPosition" style={{ height: "5vh" }} /> */}
          <img
            ref={arrowRef}
            src={ArrowDown}
            alt="Scroll to the bottom or to the beginning"
            className={
              'LandingPage__ScrollDownBtn ' +
              (arrowDirection === 'up' ? 'HomeDesktop__ScrollDownBtn--up' : '')
            }
            style={{
              width: isPhone ? '2em' : '2.3em',
              // marginLeft: isPhone ? '-1em' : '-1.15em',
              zIndex: '120',
              display: displayArrow ? 'block' : 'none',
            }}
            onClick={() => {
              const scrollPositionUp = document.getElementById('LandingPage');

              setscrolling(false);
              if (arrowDirection === 'up') {
                scrollPositionUp.scrollTo({
                  top: 0,
                });
                setarrowDirection('down');
                setdisplayWhiteArrow(false);
              } else {
                scrollPositionUp.scrollTo({
                  top: scrollPositionUp.scrollHeight - windowInnerSize.height * 2,
                });
                setarrowDirection('up');
              }

              setscrolling(true);
            }}
          />
          {/* <img src={DoubleArrowDown}
          alt='Scroll down' /> */}
          <img
            ref={whiteArrowRef}
            src={WhiteArrowDown}
            alt="Scroll to the footer"
            className={'LandingPage__ScrollToSlide6Btn'}
            style={{
              width: isPhone ? '2em' : '2.3em',
              // marginLeft: isPhone ? "-1em" : "-1.15em",
              left: isPhone ? '4.6em' : '4.85em',
              zIndex: '120',
              display: displayWhiteArrow ? 'block' : 'none',
            }}
            onClick={() => {
              const scrollPositionUp = document.getElementById('LandingPage');
              scrollPositionUp.scrollTo({ top: scrollPositionUp.scrollHeight });

              // triggerNative(scrollPositionUp, 'scroll'); // Call the event

              // dispatchEvent("scroll");
            }}
          />
          {/* // dddd */}
          <div
            ref={slide1Ref}
            className="LandingPage__Slide1 unnamed-character-style-1"
            style={{
              //   // display: "hidden",
              //   opacity: "0",
              //   position: "sticky",
              // top: windowInnerSize.height * 0.4,
              //   left: "0",
              //   padding: " 0",
              // marginTop: -windowInnerSize,
              height: windowInnerSize.height * 0.5,
              marginBottom: windowInnerSize.height * 0.25,
              //   // fontSize: "1em",
              //   color: "#ffbf12",
              //   textAlign: "center",
              //   transition: "opacity 0.5s", // Add a transition effect
            }}
          >
            <div
              className="LandingPage__Slide1Container"
              style={{
                //   display: "flex",
                height: windowInnerSize.height * 0.5,
                //   flexDirection: "column",
                //   justifyContent: "center",
              }}
            >
              <p
                ref={text1}
                className="LandingPage__Slide1Text1"
                style={{
                  fontSize: isPhone ? '0.8em' : '1.2em',
                  lineHeight: '1.15em',
                }}
              >
                Every creator’s
                <br />
                <i
                  style={{
                    fontSize: '1.46em',
                    //   lineHeight: "1.25em",
                    //   fontStyle: "normal",
                    //   color: "#fff",
                    textShadow: isPhone
                      ? '0 0 0.4vw ,0 0 0.1vw,0 0 3.1vw #ffbf12'
                      : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
                  }}
                >
                  Personal brand
                </i>
                <br /> is their
                {isPhone ? <br /> : null} startup company
              </p>
              <div>
                <p
                  ref={text2}
                  className="LandingPage__Slide1Text2"
                  style={{
                    fontSize: isPhone ? '0.8em' : '1.2em',
                    lineHeight: '1.15em',
                    opacity: 0,
                    transition: 'opacity 1.5s', // Add a transition effect
                  }}
                >
                  Prepare your {isPhone ? <br /> : ''}Personal brand
                  <br />
                  to be listed on the
                  <br />
                  <i
                    style={{
                      fontSize: '1.46em',
                      //   lineHeight: "1.25em",
                      //   fontStyle: "normal",
                      //   color: "#fff",
                      textShadow: isPhone
                        ? '0 0 0.4vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12'
                        : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
                    }}
                  >
                    Stock exchange
                  </i>
                </p>
                <p
                  ref={text3}
                  className="LandingPage__Slide1Text3"
                  style={{
                    // opacity: 0,
                    // margin: 0,
                    // lineHeight: "1.2em",
                    fontSize: isPhone ? '0.8em' : '1.3em',
                    // transition: "opacity 1.5s", // Add a transition effect
                  }}
                >
                  Right from day one!
                </p>
              </div>
            </div>
          </div>
          {/* Slide2 */}
          <div
            id="dddd"
            ref={slide2Ref}
            className=" LandingPage__Slide1 unnamed-character-style-1"
            style={{
              // marginTop: windowInnerSize*2,

              height: windowInnerSize.height * 0.5,
              marginBottom: windowInnerSize.height * 0.25,
            }}
          >
            <div
              style={{
                display: 'flex',
                height: windowInnerSize.height * 0.5,
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <p
                ref={text1S2}
                style={{
                  margin: 0,
                  fontSize: isPhone ? '1.18em' : '1.75em',
                  lineHeight: isPhone ? '1.25em' : '1.1em',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                }}
              >
                Inspire people
              </p>
              <p
                ref={text2S2}
                style={{
                  margin: 0,
                  fontSize: isPhone ? '1.18em' : '1.75em',
                  lineHeight: isPhone ? '1.25em' : '1.1em',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                }}
              >
                Make a living
              </p>
              <p
                ref={text3S2}
                style={{
                  margin: 0,
                  fontSize: isPhone ? '1.18em' : '1.75em',
                  lineHeight: isPhone ? '1.25em' : '1.1em',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                }}
              >
                Create wealth
              </p>
              <i
                ref={text4S2}
                style={{
                  color: '#fff',
                  margin: '10vh 0 0 0',
                  fontSize: isPhone ? '1.18em' : '1.75em',
                  lineHeight: isPhone ? '1.25em' : '1em',
                  textShadow: isPhone
                    ? '0 0 0.4vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12'
                    : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                  fontStyle: 'normal',
                }}
              >
                Just <br />
                By being yourself
              </i>
            </div>
          </div>
          {/* Slide3 */}
          <div
            ref={slide3Ref}
            className="LandingPage__Slide1 unnamed-character-style-1"
            style={{
              // height: windowInnerSize.height * 0.5,
              marginBottom: windowInnerSize.height * 0.25,
            }}
          >
            <div
              style={{
                display: 'flex',
                // height: windowInnerSize.height * 0.5,
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <p
                ref={text1S3}
                style={{
                  margin: 0,
                  fontSize: isPhone ? '1em' : '1.3em',
                  lineHeight: '1.15em',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                }}
              >
                Invite Your Community <br />
                To join
              </p>
              <p
                ref={text2S3}
                style={{
                  color: '#fff',
                  margin: isPhone ? '4vh 0 0 0' : '5vh 0 0 0',
                  fontSize: isPhone ? '2em' : '2.25em',
                  lineHeight: '1em',
                  textShadow: isPhone
                    ? '0 0 0.4vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12'
                    : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',

                  opacity: 0,
                  // transform: "translate(10px, 0)",
                  transition: 'opacity 1.5s', // Add a transition effect
                  // transition: "scale 1.5s", // Add a transition effect
                  // transition: "all 1s ease-out",
                  fontStyle: 'normal',
                  display: 'inline',
                }}
              >
                you
                <span
                  id="oo"
                  style={{
                    opacity: 0,
                    transition: 'opacity 1.5s', // Add a transition effect
                  }}
                ></span>
                <br />
                <p
                  ref={text3S3}
                  style={{
                    color: '#fff',
                    // fontSize: "0.75em",
                    // lineHeight: "1.2em",
                    opacity: 0,
                    transition: 'opacity 1.5s', // Add a transition effect
                    fontStyle: 'normal',
                    display: 'inline',
                  }}
                >
                  {/* {!text3s3StyleIndicator ? <br /> : null}
                {text3s3StyleIndicator ? "r" : null}
                {text3s3StyleIndicator ? <br /> : null} */}
                  journey
                </p>
              </p>
              <p
                ref={text4S3}
                style={{
                  color: '#ffbf12',
                  margin: isPhone ? '4vh 0 0 0' : '5vh 0 0 0',
                  fontSize: isPhone ? '1em' : '1.3em',
                  lineHeight: '1.15em',
                  // lineHeight: "1.2em",
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                  fontStyle: 'normal',
                }}
              >
                to the Stock Exchange{' '}
              </p>
              <p
                ref={text5S3}
                style={{
                  color: '#ffbf12',
                  margin: 0,
                  fontSize: isPhone ? '1em' : '1.3em',
                  lineHeight: '1.3em',
                  // lineHeight: "1.2em",
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                  fontStyle: 'normal',
                }}
              >
                and Beyond!
              </p>
            </div>
          </div>
          {/* Slide4 */}
          <div
            ref={slide4Ref}
            className="LandingPage__Slide1 unnamed-character-style-1"
            style={{
              // height: windowInnerSize.height,
              marginBottom: windowInnerSize.height * 0.25,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                // height: windowInnerSize.height * 0.75,
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <p
                ref={text1S4}
                style={{
                  color: '#ffbf12',
                  margin: 0,
                  fontSize: isPhone ? '0.85em' : '1em',
                  lineHeight: '1.45em',
                  // textShadow:
                  //   "0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12",
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                }}
              >
                Remember{' '}
              </p>
              <p
                ref={text2S4}
                style={{
                  margin: '0.55em 0',
                  fontSize: isPhone ? '1.6em' : '2.2em',
                  lineHeight: '1.15em',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                  textShadow: isPhone
                    ? '0 0 0.4vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12'
                    : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
                  color: '#fff',
                }}
              >
                Today’s <br />
                popularity
                {/* <br /> */}
                <i
                  style={{
                    display: 'block',

                    fontStyle: 'normal',
                    fontSize: '0.47em',
                    lineHeight: '1.1em',
                    // textShadow:
                    //   "0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12",
                    color: '#fff',
                  }}
                >
                  Is
                </i>
                {/* <br /> */}
                <i
                  style={{
                    display: 'block',

                    fontSize: '0.95em',
                    textShadow: isPhone
                      ? '0 0 0.4vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12'
                      : '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
                    fontStyle: 'normal',
                    color: '#fff',
                  }}
                >
                  Tomorrow’s <br />
                  Security
                </i>
              </p>
              <p
                ref={text3S4}
                style={{
                  color: '#ffbf12',
                  margin: ' 0.5em 0',
                  fontSize: isPhone ? '0.85em' : '1em',
                  lineHeight: '1.15em',
                  opacity: 0,
                  transition: 'opacity 1.5s', // Add a transition effect
                }}
              >
                secure your financial future Today
                <br />
                <span style={{ color: '#ffbf12' }}> Better safe than sorry</span>
              </p>
            </div>
          </div>
          <div
            ref={slide5Ref}
            className="HomeDesktop__SlideHeaderContainer HomeDesktop__Slide unnamed-character-style-1"
            id="LandingPage__JoinSlide"
            style={{
              height: windowInnerSize.height * 0.5,
              marginBottom: windowInnerSize.height * 0.25,
              position: 'relative',
              top: '0',
              left: '0',
              opacity: 0,
              transition: 'opacity 0.2s', // Add a transition effect
              zIndex: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div className="">
              <img
                className="HomeDesktop__Slide4__StarsEyesEmoji"
                src={StarsEyesEmoji}
                alt="Stars eyes emoji"
                style={{ width: isPhone ? '2.35em' : '3em' }}
              />
              <p
                className="LandingPage__Slide4__JoinTxt unnamed-character-style-1"
                style={{ fontSize: isPhone ? '1em' : '1.2em', margin: 0 }}
              >
                JOIN THE WAITLIST
              </p>
              <p
                className="headline"
                style={{
                  margin: '0 0 1em',
                  fontSize: isPhone ? '0.55em' : '0.65em',
                  fontWeight: 'lighter',
                }}
              >
                Let’s personalize it!{isPhone ? <br /> : ''} Which option describes you best?{' '}
                <img
                  src="https://em-content.zobj.net/source/apple/354/thinking-face_1f914.png"
                  style={{
                    width: '1.5em',
                    position: 'relative',
                    top: '0.4em',
                    left: '0.2em',
                  }}
                />
              </p>

              <select
                id="waitlistSelect"
                className="LandingPage__WishlistSelect"
                value={waitlistSelect ? waitlistSelect : selectedCheckbox}
                onChange={handleSelectChange}
              >
                <option value={''}>Select</option>
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
              {/* <FormControl
              sx={{
                m: 1,
                margin: "0 auto",
                position: "relative",
                width: "fit-content",
                display: "block",
              }}
              variant="standard"
            >
             
              <Select
                // labelId="demo-customized-select-label"
                native
                
                id="demo-customized-select"
                value={selectedCheckbox}
                onChange={handleSelectChange}
                input={<BootstrapInput />}
              >
                <MenuItem selected value="Select">
                  <em>Select</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem value={role}>{role}</MenuItem>
                ))}
              </Select>
            </FormControl> */}

              {/* <FormGroup sx={{ width: "22vw", margin: "0 auto" }}>
              <FormControlLabel
                onChange={() => {
                  setSelectedCheckbox(selectedCheckbox === 0 ? null : 0);
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#ffbf12",
                      },
                    }}
                    checked={selectedCheckbox === 0 ? true : false}
                  />
                }
                sx={{ textTransform: "none", fontFamily: "san-sarif" }}
                label="Creator"
              />
              <FormControlLabel
                onChange={() => {
                  setSelectedCheckbox(selectedCheckbox === 1 ? null : 1);
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#ffbf12",
                      },
                    }}
                    checked={selectedCheckbox === 1 ? true : false}
                  />
                }
                sx={{ textTransform: "none", fontFamily: "san-sarif" }}
                label="Agency"
              />
              <FormControlLabel
                onChange={() => {
                  setSelectedCheckbox(selectedCheckbox === 2 ? null : 2);
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#ffbf12",
                      },
                    }}
                    checked={selectedCheckbox === 2 ? true : false}
                  />
                }
                sx={{ textTransform: "none", fontFamily: "san-sarif" }}
                label="Business"
              />
              <FormControlLabel
                onChange={() => {
                  setSelectedCheckbox(selectedCheckbox === 3 ? null : 3);
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#ffbf12",
                      },
                    }}
                    checked={selectedCheckbox === 3 ? true : false}
                  />
                }
                sx={{ textTransform: "none", fontFamily: "san-sarif" }}
                label="Investor"
              />
              <FormControlLabel
                onChange={() => {
                  setSelectedCheckbox(selectedCheckbox === 4 ? null : 4);
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#ffbf12",
                      },
                    }}
                    checked={selectedCheckbox === 4 ? true : false}
                  />
                }
                sx={{ textTransform: "none", fontFamily: "san-sarif" }}
                label="Other"
              />
            </FormGroup> */}
              <div
                className="HomeDesktop__Slide4__EmailInputContainer"
                style={{ margin: isPhone ? '0.5em auto' : '1em auto' }}
              >
                {' '}
                <input
                  className="HomeDesktop__Slide4__EmailInput"
                  type={'email'}
                  placeholder={'EMAIL'}
                  value={userEmail}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setUserEmail('');
                    } else {
                      setUserEmail(null);
                    }
                    if (isValidateEmail(e.target.value)) {
                      setUserEmail(e.target.value);
                    }
                  }}
                  style={{ margin: isPhone ? '0 auto 1.5em' : 'auto 0' }}
                />
                <img
                  src={Done}
                  alt="Submit"
                  className="HomeDesktop__Slide4__DoneButton"
                  onClick={handleSubmit}
                />
              </div>
              <div className="LandingPage__WishListCheckBoxContainer headline">
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setisTsAndCsChecked(e.target.checked);
                    }}
                  />{' '}
                  I accept{' '}
                  <Link
                    to={'/terms-and-conditions'}
                    state={{ from: 'wishlist' }}
                    style={{ textDecoration: 'underline' }}
                  >
                    T’s & C’s
                  </Link>
                  <span className="checkmark"></span>
                </label>
                <label className="container" style={{ display: 'block' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setisPSContactChecked(e.target.checked);
                    }}
                  />{' '}
                  I consent to PassionStocks contacting me via email
                  <span className="checkmark"></span>
                </label>
              </div>

              {/* <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={open}
              autoHideDuration={10000}
              onClose={handleClose}
              // action={action}
            > */}
              {open && (
                <div style={{ width: '100%', position: 'fixed', bottom: '2em' }}>
                  <Alert
                    onClose={handleClose}
                    // autoHideDuration={10000}
                    severity={open}
                    sx={{
                      width: 'fit-content',
                      alignSelf: 'center',
                      margin: '0 auto',
                      textTransform: 'none',
                    }}
                  >
                    {message}
                  </Alert>
                </div>
              )}
              {/* </Snackbar> */}

              {/* <p className="HomeDesktop__Slide4__LoginLink headline">
            Already have an account?{" "}
            {/* <Link to={"/auth/log-in"} state={{ isMicroSite: true }}> }
            <i onClick={handleLoginBtn}>Log in</i>
            {/* </Link> }
          </p> */}
            </div>
          </div>
          <div
            ref={slide6Ref}
            id="BottomDivHD"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundImage: `url(` + PSLBckg + `)`,
              height: windowInnerSize.height,
              position: 'relative',
              top: '0',
              left: '0',
              zIndex: 0,
              opacity: 0,
              // zIndex: 100,
              transition: 'opacity 0.2s', // Add a transition effect
            }}
          >
            {/* <div id="LogoDescEndHD"> */}
            <div />
            <div>
              <img
                src={LongLogo}
                alt="LongLogo"
                className="LandingPage__LongLogo"
                style={{ width: isPhone ? '18em' : '20em' }}
              />
              <p
                className="LandingPage__FooterTxt headline"
                style={{ fontSize: isPhone ? '0.8em' : '1.2em' }}
              >
                PassionStocks is a socials to stocks platform for creators.
                <br />
                <p style={{ fontWeight: 'normal', margin: 0 }}>
                  Helping creators to transform their personal brands into stocks.
                </p>
                (And no. we are not a crypto platform)
                <br />
                <span style={{ color: '#b8b8b8' }}>______</span>
              </p>

              <p
                id="EndDescriptionHD"
                className="headline"
                style={{
                  margin: '4vh auto',
                  fontSize: isPhone ? '0.8em' : '1.1em',
                  lineHeight: '1.7em',
                  textAlign: 'center',
                }}
              >
                Empowering creators and their communities to team up and turn
                <br />
                a creator’s personal brand into a valuable startup,
                <br />
                aiming for a stock exchange listing.{' '}
              </p>
            </div>
            <div
              className="LandingPage__Footer__LinksContainer"
              style={{ flexDirection: isPhone ? 'column' : 'row' }}
            >
              <div
                className="LandingPage__Footer__Links headline"
                style={{ fontSize: isPhone ? '0.75em' : '1.2em' }}
              >
                <Link to={'/terms-and-conditions'} state={{ from: 'footer' }}>
                  <p>
                    T's {' & '}
                    C's{' '}
                  </p>
                </Link>
                {/* <Link to="/Desktop/Privacy_Policy"> */}

                {/* </Link> */}
                {/* <Link to="/Desktop/Cookies_Policy"> */}
                <Link to={'/cookie-policy'} state={{ page: '/', from: 'footer' }}>
                  <p> Cookie Policy </p>
                </Link>
                <Link to={'/privacy-policy'} state={{ from: 'footer' }}>
                  <p> Privacy Policy </p>
                </Link>
                {/* </Link> */}
                <Link
                  to="https://passionstocks.helpsite.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p> FAQs </p>
                </Link>
                {/* <p>Overview</p> */}
              </div>

              <div id="FindUsEndHD">
                <img
                  src={PSLogo}
                  alt=""
                  className="LandingPage__Footer__SMLink"
                  id="WhatsappLogoBWHD"
                  onClick={() => {
                    const scrollPositionUp = document.getElementById('LandingPage');
                    scrollPositionUp.scrollTo({
                      top: windowInnerSize.height * 18,
                    });
                  }}
                />
                <a href="https://www.facebook.com/PassionStocks">
                  <img
                    src={FacebookLogoBW}
                    alt=""
                    className="LandingPage__Footer__SMLink"
                    id="FacebookLogoBWHD"
                  />
                </a>
                <a href="https://www.instagram.com/passionstocks/">
                  <img
                    src={InstagramLogoBW}
                    alt=""
                    className="LandingPage__Footer__SMLink"
                    id="InstagramLogoBWHD"
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCewSZEyV9qce9dnuUTY5Qqw">
                  <img
                    src={YoutubeLogoBW}
                    alt=""
                    className="LandingPage__Footer__SMLink"
                    id="YoutubeLogoBWHD"
                  />
                </a>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export function isValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default LandingPage;
