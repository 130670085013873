import { useSelector } from "react-redux";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

const selectProfile = (state) => state.profile;

export default function Monitor() {
  const profile = useSelector(selectProfile);

  useEffect(() => {
    datadogRum.init({
      applicationId: "24a6cfc9-94ec-472b-9e22-c89db4d070b3",
      clientToken: "pub2243305a70b579169ce2b2e656c7193b",
      site: "datadoghq.com",
      service: "passionstocks-for-mobile",
      env: process.env.ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (profile) {
      datadogRum.setUser({
        id: profile.id,
        name: profile.name,
        email: profile.email,
        accountType: profile.accountType,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [profile]);
}
