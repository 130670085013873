
export function convertTextToPositive(text) {
    const sanitizedValue = text.replace(/[^\d]/g, '');
    const newValue = sanitizedValue === '' ? '0' : sanitizedValue.replace(/^0+/, '');
    return newValue;

}
export function adjustWidth(value, input, isPhone) {

    // Get the width of the input value
    const valueWidth = getTextWidth(numberWithCommas(value)/*.toString()*/, input.style.font);
    // Set the input width to the width of its value

    input.style.width = valueWidth < 23 ? 23 + 'px' : valueWidth + (isPhone ? 2 : 7) + 'px';
}

// Function to calculate the width of text
function getTextWidth(text, font) {
    // Create a temporary span element to measure the text width
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    span.style.whiteSpace = 'nowrap';
    span.style.font = font; // Set the font style
    span.innerText = text;

    // Append the span to the document body
    document.body.appendChild(span);

    // Get the width of the span element
    const width = span.offsetWidth;

    // Remove the span from the document body
    document.body.removeChild(span);

    return width;
}
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

