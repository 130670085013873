import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Dialog, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwipeableViews from 'react-swipeable-views';
import PSStations from './slides/PSStations';
import PSAccountManager from './slides/PSAccountManager';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    // height: '100vh',
    overflow: 'hidden',
    padding:0
  },
  slide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: '#fff',
    fontSize: '30px',
  },
  slide1: {
    backgroundColor: '',
  },
  slide2: {
    backgroundColor: '',
  },
  slide3: {
    backgroundColor: '',
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  navButtonLeft: {
    left: 0,
  },
  navButtonRight: {
    right: 0,
  },
});

const WelcomeSlide = (props) => {
  const classes = useStyles();

  // use for slides height elements:
  const [height, setheight] = useState(0);

  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };


  return (
    <Box className={classes.root} sx={{padding:0}}>
      {/* <SwipeableViews
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        <div> */}
          <PSStations isPhone={props.isPhone} className={`${classes.slide}`} setIndex={setIndex} setheight={setheight}/>{' '}
        {/* </div>
        <div>
          <PSAccountManager className={`${classes.slide}`} setIndex={setIndex} height={height}/>
        </div>
      </SwipeableViews> */}
   
    </Box>
  );
};

export default WelcomeSlide;
