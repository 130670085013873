import "./style.css";

import OpeningLogo from "../../images/ps-logo-2-rows.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function OpeningView(props) {
  const [isDisplay, setisDisplay] = useState(null);

  const pathname = useLocation();

  useEffect(() => {
    // console.log(pathname);
    if (pathname.pathname === "/") {
      setisDisplay(true);
      setTimeout(() => {
        // store.dispatch({ type: "ui/openingViewDM/toggle", payload: false });
        setisDisplay(false);
      }, 4000);
    }
  }, []);

  return (
    isDisplay && (
      <div id="OpeningViewDiv">
        <img src={OpeningLogo} alt="PassionStocks logo" id="OpeningLogo" />
      </div>
    )
  );
}
