import axios from 'axios';

// Function to fetch data of brand calculator
async function fetchData() {
    try {
        const response = await axios.get(
            'https://test-api.passionstocks.com/issuers/money-calculator');
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}

// process brand value by followers amount
function calcBrandValues(followers, data) {
    let tierObj = data[data.length - 1];
    for (let i = 0; i < data.length; i++) {
        if (followers <= data[i].followers_max) {
            tierObj = data[i];
            break;
        }
    }
    let earningWitoutGrowth = followers * 0.01 * tierObj.price
    let earnings = {
        min: earningWitoutGrowth * Math.pow(1 + tierObj.monthlyGrowthRate1, tierObj.timePeriod),
        max: earningWitoutGrowth * Math.pow(1 + tierObj.monthlyGrowthRate2, tierObj.timePeriod),
    }
    let worth = {
        min: earnings.min * 10,
        max: earnings.max * 20
    }
    let brandValues = {
        earnings: { min: Math.floor(earnings.min), max: Math.floor(earnings.max) },
        worth: { min: Math.floor(worth.min), max: Math.floor(worth.max) }
    }
    return brandValues;
}

export { fetchData, calcBrandValues };
