import formatNumberToAbbString from '../../../../../../helpers/formatNumberToAbbString';
import unicorn from '../../../../../../images/unicorn.png';

export default function UnicornIndicator_mobile(props) {
  const { totalWorth, unicornRatio } = props;
  return (
    <div
      className="unnamed-character-style-1"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '90vw',
        position: 'relative',
        margin: '0 auto',
      }}
    >
      <div>
        <div style={{ width: '100%', color: '#ffbf12', fontSize: '3vw', lineHeight: '3.2vw' }}>
          <p
            style={{
              margin: 0,
              position: 'relative',
              left: `${unicornRatio<71?unicornRatio:67}%`,
              margin: 0,
              transition: 'left 0.75s ease-out 0s',
              width:'28vw',
              textAlign:unicornRatio<71?'left':'right'
            }}
          >
            {' '}
            {formatNumberToAbbString(totalWorth?.min)}-{formatNumberToAbbString(totalWorth?.max)} (
            {Math.ceil(unicornRatio)}%)
          </p>
          <p
            style={{
              margin: '0 0 1.5vw',
              position: 'relative',
              left: `${unicornRatio}%`,
              margin: 0,
              transition: 'left 0.75s ease-out 0s',
            }}
          >
            |
          </p>
        </div>
        <div
          style={{ width: '80vw', border: '0.5vw solid white', borderRadius: '100px', margin: 0 }}
        >
          <div
            style={{
              background: '#ffbf12',
              margin: 0,
              borderRadius: '10vw',
              height: '14px',
              width: `${unicornRatio}%`,
              transition: 'width 0.75s ease-out 0s',
            }}
          />
        </div>
      </div>{' '}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',marginLeft:'1vw' }}>
        <img src={unicorn} alt="Unicorn" style={{ width: '9vw' }} />
        <p
          style={{
            fontSize: '3vw',
            lineHeight: '0vw',
            margin: '2vw 0 0',
          }}
        >
          $1B
        </p>
      </div>
    </div>
  );
}
