import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";

import Monitor from "../../monitor";
import UpdateVersion from "../../utiles/updateVersion";
import OpeningView from "../../pages/opening-view";

export default function Root(props) {
  const { pathname } = useLocation();
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <UpdateVersion />
      <Monitor />

      <OpeningView />
      {/* {props.isPhone && <CookiesMessage />} */}
      {/* {props.isPhone && <AgentViewStats />} */}

      <div id="app-frame">
        <Outlet />
      </div>
    </div>
  );
}
