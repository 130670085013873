import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store/store.js';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";


import store from './store/store.js';
import './styles/app-style.css'
import './styles/XD_home.css'
import './style.css'

import Root from './pages/root/index.jsx';
import LandingPage from './pages/landing-page/index.jsx';
import GeneralError from './pages/errors/general/index.jsx';
import TsAndCs from './pages/ts-and-cs/index.jsx';
import CookiePolicy from './pages/cookie-policy/index.jsx';
import PrivacyPolicy from './pages/privacy-policy/index.jsx';

window.addEventListener('resize', function (event) {
  store.dispatch({
    type: "ui/windowInnerSize/add",
    payload: { height: window.innerHeight, width: window.innerWidth },
  });
}, true);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<GeneralError />} >
      <Route path="/" element={<LandingPage />} />
      <Route path="terms-and-conditions" element={<TsAndCs />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Route>
  ), {});
const root = ReactDOM.createRoot(document.getElementById('root1'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
