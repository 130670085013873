import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";

import BackBtn from "../../images/back-page.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function PrivacyPolicy() {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, []);

  const [apperance, setapperance] = useState(null);
  useEffect(() => {
    setapperance(true);

    return () => {
      setapperance(false);
    };
  }, []);

  useEffect(() => {
    if (apperance) {
      const scrollPositionUp = document.getElementById("PrivacyPolicy");
      if (location.state?.from === "") {
        document.getElementById("CookieLink").scrollIntoView();
      }
    }
  }, [apperance]);

  return (
    apperance && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.75}
        direction="normal"
        keyframes={[
          "transform: translateY(100vh)",
          "transform: translateY(50vh)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          id="PrivacyPolicy"
          className="PrivacyPolicy Page headline"
          style={{ height: windowInnerSize.height }}
        >
          <img
            src={BackBtn}
            alt="Back to previous page"
            className="CookiePolicy__back-btn"
            onClick={() => {
              // console.log(location.state?.from);
              if (location.state?.page === "/cookie-policy") {
                navigate("/cookie-policy", {
                  state: { from: location.state?.from, page: "/" },
                });
              } else {
                navigate("/", { state: { from: location.state?.from } });
              }
            }}
          />

          <div
            style={{
              padding: " 2vw 5vw 5vw",
              maxWidth: "1400px",
              margin: "0 auto",
            }}
          >
            <h1>PassionStocks&rsquo;s Privacy Policy</h1>
            <br />
            <p>
              Please read this privacy policy (the &ldquo;Policy&rdquo;)
              carefully to understand how we use personal information. If you do
              not agree with this Policy, your choice is not to use
              PassionStocks.com site and mobile applications and its related
              sites, applications, services and goods or any other website
              operated by PassionStocks that links to this Policy (the
              &ldquo;Site&rdquo;).&nbsp;
            </p>
            <p>
              By accessing or using this Site, you agree to this Policy. This
              Policy may change from time to time; any changes we make to this
              Policy will be posted on this Site, we will also take any other
              steps, to the extent required by applicable law, including
              notifying you and/or seeking your explicit consent to material
              changes. Changes to this Policy are effective as of the stated
              &quot;Last Updated&quot; date. Other than where we have sought
              such explicit consent from you, your continued use of the Site
              after we make changes will constitute acceptance of, and agreement
              to be bound by, those changes, so please check the Policy
              periodically for any updates or changes.
            </p>
            <br />
            <ul>
              <li>
                <p> The short version</p>
              </li>
              <li>
                <p> The longer version</p>
              </li>
              <li>
                <p> Information We Collect</p>
              </li>
              <li>
                <p> How Do We Collect Information?</p>
              </li>
              <li>
                <p>How Do We Use The Information Collected?</p>
              </li>
              <li>
                <p>How Long Do We Keep Personal Information?</p>
              </li>
              <li>
                <p> Children Under the Age of 13</p>
              </li>
              <li>
                <p>Sharing Personal Information with Third Parties</p>
              </li>
              <li>
                <p>Where We Store Your Personal Information</p>
              </li>
              <li>
                <p> Cookies</p>
              </li>
              <li>
                <p> Do Not Track</p>
              </li>
              <li>
                <p> External Links</p>
              </li>
              <li>
                <p> Security</p>
              </li>
              <li>
                <p> Rights of EU Users</p>
              </li>
              <li>
                <p>Specific Provisions for California Residents</p>
              </li>
              <li>
                <p> Updating Personal Information</p>
              </li>
              <li>
                <p> Contact Us</p>
              </li>
            </ul>
            <br />
            <h2>
              <u>The short version</u>
            </h2>
            <br />
            <p>
              <span> Information We Collect&nbsp;&ndash; </span>When you
              register to the Site, use it, complete forms, or register to our
              affiliate or influencer or similar program, we collect the
              personal information provided by you. We also collect information
              about your communications with PassionStocks as well as
              communication with other users of PassionStocks.&nbsp;
            </p>
            <p>
              In addition, we collect information while you access, browse, view
              or otherwise use the Site.
            </p>
            <p>
              <span> How Do We Collect Information?&nbsp;- </span>You directly
              provide us with most of the information we collect. We also
              collect technical information indirectly and automatically through
              our systems. We also collect information from third party vendors
              and/or other commercially available sources.&nbsp;
            </p>
            <p>
              <span>How Do We Use the Information Collected?&nbsp;&ndash;</span>
              We use personal information to provide you with quality service
              and security, to operate the Site and to perform our obligations
              to you; to ensure marketplace integrity and security; to prevent
              fraud; to contact you and send you direct marketing
              communications; to promote and advertise the Site and
              PassionStocks marketplace; to comply with lawful requests by
              public authorities and to comply with applicable laws and
              regulations.&nbsp;
            </p>
            <p>
              <span>How Long Do We Keep Personal Information&nbsp;&ndash;</span>
              We will keep personal information only for as long as is required
              to fulfill the purpose for which it was collected. However, in
              some cases we will retain personal information for longer periods
              of time.&nbsp;
            </p>
            <p>
              <span>Children Under the Age of 13&nbsp;-</span> Our Site is not
              intended for children under 13 years of age and we do not
              knowingly collect personal information from children under
              13.&nbsp;
            </p>
            <p>
              <span>
                Sharing Personal Information with Third Parties&nbsp;&ndash;{" "}
              </span>
              We share personal information with third parties in order to
              operate the Site, provide our services to you, fulfill obligations
              imposed on us by applicable laws and regulations, and prevent
              fraud, infringements and illegal activities.&nbsp;
            </p>
            <p>
              <span> Where We Store Personal Information&nbsp;-</span> Some of
              the personal information you provide to us will be stored or
              processed on our behalf by third party suppliers and data
              processors and may be located in other jurisdictions, such as
              the&nbsp;
              <r style={{ color: "red" }}>
                United States, Israel or the Republic of South Africa
              </r>
              .
            </p>
            <p>
              <span>Cookies&nbsp;-</span> We use cookies and similar
              technologies (such as web beacons, pixels, tags, and scripts) to
              improve and personalize your experience, provide our services,
              analyze website performance and for marketing purposes.
            </p>
            <p>
              <span> Do Not Track (DNT)&nbsp;&ndash;</span> Our Site does not
              respond to Do Not Track (DNT) signals.&nbsp;
            </p>
            <p>
              External Links - the Site contains links to third party sites and
              if you link to a third party site from the Site, any data you
              provide to that site and any use of that data by the third party
              are not under the control of PassionStocks and are not subject to
              this Policy.&nbsp;
            </p>
            <p>
              <span>Security&nbsp;&ndash;</span> We take great care in
              maintaining the security of the Site and your information and in
              preventing unauthorized access, loss, misuse, alteration,
              destruction or damage to it through industry standard technologies
              and internal procedures.
            </p>
            <p>
              <span>Rights of EU Users&nbsp;&ndash;</span> Where we process
              personal data related to EU user, further terms apply to our
              processing in relation to your rights as a data subject under EU
              data protection laws, as detailed below.&nbsp;
            </p>
            <p>
              <span> Specific Provisions for California Residents-</span> If you
              are a California resident, you are entitled to specific privacy
              rights as detailed below.
            </p>
            <p>
              <span>Updating Personal Information&nbsp;&ndash;</span> We take
              steps to ensure that the personal information we collect is
              accurate and up to date, and we provide you with the opportunity
              to update your information through your account profile settings.
              In the event that you believe your information is in any way
              incorrect or inaccurate, please let us know immediately.&nbsp;
            </p>
            <p>
              <span>Contact Us&nbsp;-</span> You can exercise your rights over
              your personal information, by contacting our customer service
              at&nbsp;
              <a
                href="mailto:info@passionstocks.com"
                style={{ color: "red", textDecoration: "underline" }}
              >
                info@passionstocks.com
              </a>
              &nbsp;. As well as if you do not have an active PassionStocks
              account, please contact us at&nbsp;
              <a
                href="mailto:info@passionstocks.com"
                style={{ color: "red", textDecoration: "underline" }}
              >
                info@passionstocks.com
              </a>
              . More details about the rights applicable to you are in the long
              version of the Policy.
            </p>
            <br />
            <p>
              The above are just the highlights. We encourage you to read more
              about the information we collect, how we use it, understand the
              meaning of cookies (no, it can&rsquo;t be eaten) and more in the
              long version of our Policy below.
            </p>
            <br />
            <h2>
              <u>The longer version</u>
            </h2>
            <p>
              PassionStocks (including Virtual Crypto SA Pty Ltd. with
              registration num.2018/343797/07. and its affiliates) respects your
              privacy and is committed to protect the personal information of
              its users - buyers, sellers, collaborators and other visitors
              browsing the Site (&ldquo;Users&rdquo;) as well as affiliates,
              influencers and other collaborators interacting with
              PassionStocks.&nbsp;
            </p>
            <p>
              We believe that you have a right to know our practices regarding
              the information we collect when you visit and/or use the
              Site.&nbsp;
            </p>
            <p>
              This Policy explains our privacy practices for the Site and
              services provided by PassionStocks as well as privacy practices
              that apply to affiliates, influencers and other collaborators who
              are individuals.&nbsp;
            </p>
            <p>
              By accessing and/or using the Site and its related sites,
              applications, services, goods and/or registering for a
              PassionStocks account and/or using it as a collaborator to share
              comments on a specific project and/or registering to our affiliate
              or influencer or similar program, you agree to the terms and
              conditions of this Policy, including to our collection, use,
              disclosure, processing and retention of personal information. You
              can also learn how to limit sharing of information in this Policy.
            </p>
            <br />
            <h2>Information We Collect</h2>
            <p>
              When you register to the Site, use it, complete forms, or use it
              as collaborator, and/or register to our affiliate or influencer or
              similar program, we ask you to provide certain personal
              information, including a valid email address, Facebook or Google
              account login details and username. We will also ask you to
              provide or otherwise collect additional information that you
              provide us, such as, your profile details, physical address or
              billing information, telephone number or other contact details,
              financial information (such as payment method and credit card
              number), taxpayer information and forms, details about other
              social networks linked accounts, details about your listed
              products, purchases, education, profession and expertise, and
              additional authentication information (such as your government
              issued ID, passport, or driving license, as permitted by
              applicable laws).&nbsp;
            </p>
            <p>
              We also collect information about your communications with
              PassionStocks as well as communication with other users of
              PassionStocks.
            </p>
            <p>
              We also collect information while you access, browse, view or
              otherwise use the Site.&nbsp;
            </p>
            <p>
              In other words, when you access the Site we are aware of your
              usage of the Site, and gather, collect and record the information
              relating to such usage, including geo-location information, IP
              address, device and connection information, browser information
              and web-log information, and all communications recorded by Users
              through the Site.&nbsp;
            </p>
            <p>
              We use that information to enhance user experience, personalize
              your browsing experience as well as monitor the Site for
              preventing fraud and inappropriate content or behavior.
            </p>
            <p>
              We also collect supplemental information obtained from third
              parties such as demographic and navigation data, if
              applicable.&nbsp;
            </p>
            <p>
              We combine your personal information with information we collect
              automatically or obtain from other companies and use it to improve
              and personalize our services, content and advertising and/or to
              prevent fraud.&nbsp;
            </p>
            <p>
              Additionally, in order to improve your online experience at
              PassionStocks, we have implemented impression reporting.&nbsp;
            </p>
            <p>
              While you view our ads, we gather user Global Unique Identifier,
              HTTP request data like, user agent, IP, host, URL,
              country/continent from which request made, browser info,
              device/operating system/operating system version.
            </p>
            <p>
              Once you register, your username, country, and additional
              information regarding your activity is made public and is visible
              to all Users of the Site.&nbsp;
            </p>
            <p>
              This information includes photos you upload, your published
              portfolio, products &amp; services information, ratings, and
              additional information you may choose to add to your
              profile.&nbsp;
            </p>
            <p>
              We also offer you the opportunity to participate and post content
              publicly in forums, blogs and on other similar features. Please be
              aware that certain information, such as your user name and
              profile, together with the content uploaded by you, will be
              publicly displayed when you use such features.&nbsp;
            </p>
            <br />
            <h2>How Do We Collect Information?</h2>
            <p>
              <span> Information provided by you&nbsp;- &nbsp;</span>You
              directly provide us with most of the information we collect. You
              do this by filling out the registration details on the Site,
              linking to your PassionStocks account accounts of other social
              networks (
              <r style={{ color: "red" }}>
                please also see the External Links section below), completing
                application forms, skill tests and customer surveys (if any
              </r>
              ) as well as by posting and sharing additional information
              voluntarily. This can include information about sellers and their
              products or services, education and profession, sharing of
              portfolio, ratings and feedbacks by buyers and anything you choose
              to add to your user profile.
            </p>
            <br />
            <p>
              <span>Information we collect when you use the Site&nbsp;- </span>
              We collect technical information indirectly and automatically
              through our systems. This information includes logging your
              Internet Protocol (IP) address, software configuration, operating
              system and use of cookies (cookies are small files sent from us to
              your computer and sometimes back). Cookies ultimately help us
              improve your navigation and ease of use of our Site. You can find
              further information about Cookies in our&nbsp;
              <Link
                to={"/cookie-policy"}
                state={{ page: "/privacy-policy", from: "" }}
                style={{ color: "red", textDecoration: "underline" }}
              >
                <span id="CookieLink">Cookie Policy.</span>
              </Link>
            </p>
            <br />
            <p>
              <span> Information we collect from other sources&nbsp;-</span> We
              receive information from third party vendors and/or other
              commercially available sources, such as:
            </p>
            <ul>
              <li>
                <p>
                  Third party vendors who provide us data to supplement the
                  information we collect about you, in accordance with
                  applicable laws. For example, we receive fraud warnings from
                  service providers for our fraud prevention and risk assessment
                  efforts. We also receive information from enrichment service
                  providers to enhance and personalize your experience as well
                  as from advertising and marketing partners in order to manage
                  and measure our campaigns.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  If you link, connect, or login to your PassionStocks Account
                  with a third-party service (e.g. Google, Facebook, LinkedIn),
                  we receive certain information, such as your registration and
                  profile information from that service. This information varies
                  and is controlled by that service or as authorized by you via
                  your privacy settings at that service.
                </p>
              </li>
              <li>
                <p>
                  Advertising and marketing partners in order to monitor, manage
                  and measure our campaigns.
                </p>
              </li>
              <li>
                <p>
                  We also receive certain information about you, if you are
                  invited to PassionStocks by another user (for example, as part
                  of our collaboration tools and networker/referral program), in
                  such case we will receive limited information, such as your
                  email address or other contact information, and we will use it
                  for the same purpose it was provided and in accordance with
                  the terms of this Privacy Policy.
                </p>
              </li>
            </ul>
            <br />
            <p>
              {" "}
              <span>How Do We Use The Information Collected?</span>
              <br />
              Where relevant under applicable laws, all processing of your
              personal information will be justified by a &quot;lawful
              ground&quot; for processing. In the majority of cases, processing
              will be justified on the basis that:
            </p>
            <ul>
              <li>
                <p>
                  you have provided your consent for us to use your personal
                  information for a specific purpose;
                </p>
              </li>
              <li>
                <p>
                  our use of your personal information is necessary to perform a
                  contract or take steps to enter into a contract with you (e.g.
                  to provide you with services which you have purchased);
                </p>
              </li>
              <li>
                <p>
                  the processing is necessary to comply with a relevant legal
                  obligation or regulatory obligation that we have (e.g. fraud
                  prevention); or
                </p>
              </li>
              <li>
                <p>
                  the processing is necessary to support our legitimate
                  interests as a business (e.g. to improve our services to you),
                  subject to your interests and fundamental rights and provided
                  it is conducted at all times in a way that is proportionate.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <span>
                We will use your personal information for the following
                purposes:
              </span>
            </p>
            <ul>
              <li>
                <p>
                  to provide you with quality service and security, to operate
                  the Site, to perform our obligations to you and to develop and
                  improve our service. For example, we use the information
                  collected from you to verify your identity. We also use this
                  information to establish and set up your account, verify or
                  re-issue a password, log your activity, enable your
                  communications with other members, provide customer support
                  and contact you from time to time. The information helps us to
                  develop and improve our services to you, to understand and
                  analyze your preferences and performance and to customize and
                  personalize your experience (including by making Products or
                  Services suggestions, ranking search results, etc.).
                </p>
              </li>
              <li>
                <p>
                  to ensure marketplace integrity, prevent fraud and maintain a
                  safe and secure marketplace. For example, we use your
                  information to track and prevent fraudulent activities and
                  other inappropriate activities, monitor content integrity,
                  conduct security investigations and risk assessments, verify
                  or authenticate information provided by you, enforce our Terms
                  of Service and comply with applicable laws. We conduct certain
                  behavioral analytics to achieve the above objectives and in
                  limited cases, if we detect activity that we think poses a
                  risk to the PassionStocks marketplace, other users, our
                  community, or third parties, automated processes may restrict
                  or limit your ability to use PassionStocks. If you would like
                  to challenge any such decision, please contact us
                  at&nbsp;info@passionstocks.com.
                </p>
              </li>
              <li>
                <p>
                  to contact you, as requested by you or as otherwise approved
                  by you or permitted according to this Policy.
                </p>
              </li>
              <li>
                <p>
                  to promote and advertise the Site and the PassionStocks
                  marketplace. For example, we use the information collected
                  from you for the purpose of sending direct marketing messages
                  (as detailed below), to show you information that may be of
                  interest to you, to organize and facilitate referral programs,
                  contests or other promotional activities or events.
                </p>
              </li>
              <li>
                <p>
                  to maintain appropriate business records, to comply with
                  lawful requests by public authorities and to comply with
                  applicable laws and regulations or as otherwise required by
                  law.
                </p>
              </li>
            </ul>
            <p>
              We will ask for your consent before using information for a
              purpose other than those set out in this Policy.
            </p>
            <br />
            <p>
              <span>Direct marketing:</span>
            </p>
            <ul>
              <li>
                <p>
                  We use your personal information to send you direct marketing
                  communications about our products, services or promotions from
                  PassionStocks that may be of interest to you or our related
                  services. This may be via email, post, SMS, telephone or
                  targeted online advertisements.
                </p>
              </li>
              <li>
                <p>
                  Our processing of your personal information for marketing
                  purposes is based on our legitimate interest, or on your
                  consent, or as otherwise permitted according to applicable
                  laws. You have a right to prevent direct marketing of any form
                  at any time - this can be exercised by following the opt-out
                  link attached to each communication or by sending an email to
                  our customer service.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  We take steps to limit direct marketing to a reasonable and
                  proportionate level, and to send you communications which we
                  believe may be of interest or relevance to you, based on the
                  information we have about you.
                </p>
              </li>
            </ul>
            <br />{" "}
            <p>
              {" "}
              <span>How Long Do We Keep Personal Information? </span>
            </p>
            <p>
              We apply a general rule of keeping personal information only for
              as long as is required to fulfill the purpose for which it was
              collected. However, in some circumstances, we will retain your
              personal information for longer periods of time. We will retain
              personal information for the following purposes:
            </p>
            <ul>
              <li>
                <p>
                  as long as it is necessary and relevant for our operations,
                  e.g. so that we have an accurate record of your dealings with
                  us in the event of any complaints or challenge; and
                </p>
              </li>
              <li>
                <p>
                  in relation to personal information from closed accounts to
                  comply with applicable laws, prevent fraud, collect any fees
                  owed, resolve disputes, troubleshoot problems, assist with any
                  investigation, enforce our Site terms and take other actions
                  as permitted by law.
                </p>
              </li>
            </ul>
            <br />
            <p>
              {" "}
              <span>Children Under the Age of 13 </span>
            </p>
            <p>
              Our Site is not intended for children under 13 years of age. No
              one under age 13 should provide any personal information to or on
              the Site. We do not knowingly collect personal information from
              children under 13. Parents and guardians should at all times
              supervise their children&apos;s activities. If we learn we have
              collected or received personal information from a child under 13,
              we will delete that personal information. If you believe we might
              have any information from or about a child under 13, please
              contact us at&nbsp;info@PassionStocks.com.&nbsp;
            </p>
            <br />
            <span>
              {" "}
              <p>Sharing Personal Information with Third Parties</p>
            </span>
            <p>
              We do not sell or rent your personal information to third parties
              for their marketing purposes without your explicit consent.
            </p>
            <br />
            <p>
              {" "}
              <span>
                We share your personal information with third parties as
                detailed below:
              </span>
            </p>
            <ul>
              <li>
                <p>
                  We share personal information with service providers that
                  provide us with services for the purpose of operating the
                  Site, opening and operating your account as well as providing
                  ancillary services and solutions. These include, among others,
                  hosting services, billing and payment vendors, CRM systems,
                  forum hosting, community management services, data and
                  cybersecurity services, web analytics and performance tools,
                  translation tools, IT SaaS services, session recording,
                  communication systems, mailing systems, data optimization and
                  marketing services, data enrichment services, legal and
                  financial advisors or technical consultants. Consistent with
                  applicable legal requirements, we take commercially reasonable
                  steps to require third parties to adequately safeguard your
                  personal information and only process it in accordance with
                  our instructions;
                </p>
              </li>
              <li>
                <p>
                  We share personal information to law enforcement agencies,
                  public authorities or other parties in order to respond to a
                  subpoena or court order, judicial process or to regulatory
                  authorities, if we believe we are required to do so by law, or
                  that doing so is reasonably necessary to comply with legal
                  processes; when we believe it necessary or appropriate to
                  disclose personal information to law enforcement authorities,
                  such as to investigate actual or suspected fraud or violations
                  of law, breaches of security, or breaches of this Policy; to
                  respond to claims against us; and to protect the rights,
                  property, or personal safety of PassionStocks, our customers,
                  or the public;
                </p>
              </li>
              <li>
                <p>
                  We share personal information with payment processors, fraud
                  detection agencies and similar third parties for the purpose
                  of facilitating payments done via the Site and securing the
                  Site and protecting it against fraud, unauthorized
                  transactions (such as money laundering), claims or other
                  liabilities;
                </p>
              </li>
              <li>
                <p>
                  Our Site includes certain social media features, including
                  single sign on features. We also share limited personal
                  information with such social media platforms and marketing
                  partners, such as Facebook and Google, to promote our services
                  or the PassionStocks marketplace. Such features and third
                  party services collect certain information about you and may
                  set a cookie to enable their functionality. Such third
                  parties&rsquo; features are governed by their policies.
                </p>
              </li>
              <li>
                <p>
                  We share personal information to help facilitate interactions
                  between Users. For example, &nbsp;if you are a Buyer we may
                  share certain limited information about you (such as the fact
                  it is your first purchase on PassionStocks) with the Seller to
                  ensure the services you receive from the Seller will fit your
                  needs; such sharing of information is limited in scope, will
                  not include personal identifying information (PII) which is
                  not already available to the Seller via the marketplace, and
                  is only intended to improve the services provided by the
                  Seller and your user experience;
                </p>
              </li>
              <li>
                <p>
                  We share personal information with PassionStocks&rsquo;s
                  corporate affiliates (
                  <span style={{ color: "red" }}>
                    including third-party service providers
                  </span>
                  ) that are related by common control for the purpose of
                  operating the Site, providing our services to you and for
                  other purposes listed herein; and
                </p>
              </li>
              <li>
                <p>
                  Personal information will also be disclosed if we go through a
                  business transition such as a merger, sale, transfer of all or
                  a portion of PassionStocks&apos;s assets, acquisition,
                  bankruptcy or similar event. In the event that we sell any
                  business or assets, we will disclose your data to the
                  prospective buyer. If we or substantially all of our assets
                  are acquired by a third party, information held by us about
                  our users will be one of the transferred assets.
                </p>
              </li>
            </ul>
            <p>
              Please note, your profile information and additional information
              regarding your activity (including services or products, reviews,
              posts on our forum, etc.) is made public and is visible to all
              Users of the Site.&nbsp;
            </p>
            <p>
              When you publish your information and/or share your data with
              other PassionStocks Users, you do so at your own risk.&nbsp;
            </p>
            <p>
              If you choose to share personal information with other users,
              including Sellers providing you a service via PassionStocks, such
              personal information is shared with other users as separate
              controllers. While PassionStocks strives to protect your privacy
              and our Terms of Service require our Users to keep the information
              shared with them confidential, PassionStocks cannot ensure that
              all Users will do so and it is your responsibility to ensure that
              other users will comply with your standards.
            </p>
            <p>
              This Site is also protected by reCAPTCHA and the&nbsp;
              <a href="https://policies.google.com/privacy">
                Google Privacy Policy
              </a>{" "}
              and&nbsp;
              <a href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}
              apply with respect to data collected by reCAPTCHA. The use of
              Google reCAPTCHA is required in order to prevent fraud and misuse
              of the Site by use of automated machines. To learn more, please
              see&nbsp;
              <a href="http://www.google.com/intl/en/policies/privacy/">
                Google&apos;s privacy policy
              </a>
              .
            </p>
            <br />
            <p>
              <span>Where We Store Your Personal Information</span>
            </p>
            <p>
              Some of the personal information you provide to us will be stored
              or processed on our behalf by third party suppliers and data
              processors and may be located in other jurisdictions, such as the
              United States, Israel and/or the Republic of South Africa, whose
              laws may differ from the jurisdiction in which you live. Whether
              to third parties or internally, any transfers of personal
              information from the European Economic Area (&ldquo;EEA&rdquo;) to
              countries not deemed to provide an adequate level of data
              protection are governed by European Union (EU) standard
              contractual clauses and/or equivalent data transfer regulations to
              protect the security and confidentiality of personal information.
              We will take all steps reasonably necessary to ensure that your
              personal information is treated securely and in accordance with
              this Policy.
            </p>
            <br />
            <p>
              {" "}
              <span>Cookies </span>
            </p>
            <p>
              We use cookies and similar technologies (such as web beacons,
              pixels, tags, and scripts) to improve and personalize your
              experience, provide our services, analyze website performance and
              for marketing purposes. To learn more about how we and out third
              party service providers use cookies and your control over these
              Cookies, please see our&nbsp;
              <Link
                to="/cookie-policy"
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Cookie Policy
              </Link>
              .
            </p>
            <p>Do Not Track</p>
            <p>
              Do Not Track (DNT) is a privacy preference that users can set in
              some web browsers, allowing users to opt out of tracking by
              websites and online services. We do not honor browser requests not
              to be tracked online (known as &ldquo;Do Not Track&rdquo;), but
              our&nbsp;{" "}
              <Link
                to="/cookie-policy"
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Cookie Policy
              </Link>
              &nbsp;describes how you can opt out of receiving cookies.
            </p>
            <br />
            <p>
              {" "}
              <span>External Links </span>
            </p>
            <p>
              Please note that the Site contains links to third party sites and
              if you link to a third party site from the Site, any data you
              provide to that site and any use of that data by the third party
              are not under the control of PassionStocks and are not subject to
              this Policy.&nbsp;
            </p>
            <p>
              You should consult the privacy policies of each site you visit.
              This Policy applies solely to personal information collected by
              our Site. If you upload content, including personal information,
              to a social network and then tag the Site, your submission will be
              subject to that social network&apos;s terms of use and privacy
              policy, even where you post on an official PassionStocks page on
              the social network. We do not have control over these terms of use
              and privacy policies and have not reviewed their adequacy. You
              should therefore review these before submitting any personal
              information.
            </p>
            <br />
            <p>
              {" "}
              <span>Security </span>
            </p>
            <p>
              We take great care in maintaining the security of the Site and
              your information and in preventing unauthorized access, loss,
              misuse, alteration, destruction or damage to it through industry
              standard technologies and internal procedures. Among other things,
              we regularly maintain a PCI DSS (Payment Card Industry Data
              Security Standards) certification (with respect to payment by
              credit cards). In addition, we contractually ensure that any third
              party processing your personal information equally provides for
              confidentiality and integrity of your data in a secure way.
              However, the transmission of data via the internet is not
              completely secure, and although we will do our best to protect
              your personal information, we cannot guarantee the security of
              your data transmitted to the Site; any transmission is at your own
              risk. Once we have received your data, we will use strict
              procedures and security features to try to prevent unauthorized
              access.
            </p>
            <p>
              Users who have registered to the Site agree to keep their password
              in strict confidence and not disclose such password to any third
              party.
            </p>
            <p>
              Further information about our data security practices can be
              provided on request.
            </p>
            <br />
            <p>
              <span>Rights of EU Users</span>
            </p>
            <p>
              Under applicable EU regulation, you have the following rights in
              respect of your personal information:
            </p>
            <ul>
              <li>
                <p>
                  to obtain a copy of your personal information together with
                  information about how and on what basis that personal
                  information is processed;
                </p>
              </li>
              <li>
                <p>to rectify inaccurate personal information;</p>
              </li>
              <li>
                <p>
                  to erase your personal information in limited circumstances
                  where (a) you believe that it is no longer necessary for us to
                  hold your personal information; (b) we are processing your
                  personal information on the basis of legitimate interests and
                  you object to such processing, and we cannot demonstrate an
                  overriding legitimate ground for the processing; (c) where you
                  have provided your personal information to us with your
                  consent and you wish to withdraw your consent and there is no
                  other ground under which we can process your personal
                  information; and (d) where you believe the personal
                  information we hold about you is being unlawfully processed by
                  us;
                </p>
              </li>
              <li>
                <p>
                  to restrict processing of your personal information where: (a)
                  the accuracy of the personal information is contested; (b) the
                  processing is unlawful but you object to the erasure of the
                  personal information; (c) we no longer require the personal
                  information for the purposes for which it was collected, but
                  it is required for the establishment, exercise or defense of a
                  legal claim or (d) you have objected to us processing your
                  personal information based on our legitimate interests and we
                  are considering your objection;
                </p>
              </li>
              <li>
                <p>
                  to object to decisions which are based solely on automated
                  processing or profiling;
                </p>
              </li>
              <li>
                <p>
                  where you have provided your personal information to us with
                  your consent, to ask us for a copy of this data in a
                  structured, machine-readable format and to ask us to share
                  (port) this data to another data controller; or
                </p>
              </li>
              <li>
                <p>
                  to obtain a copy of or access to safeguards under which your
                  personal information is transferred outside of the EEA.
                </p>
              </li>
            </ul>
            <p>
              In addition to the above, you have the right to lodge a complaint
              with a supervisory authority for data protection.
            </p>
            <p>
              We will ask you for additional data to confirm your identity and
              for security purposes, before disclosing data requested by you. We
              reserve the right to charge a fee where permitted by law. We will
              decline to process requests that jeopardize the privacy of others,
              are extremely impractical, or would cause us to take any action
              that is not permissible under applicable laws. Additionally, as
              permitted by applicable laws, we will retain where necessary
              certain personal information for a limited period of time for
              record-keeping, accounting and fraud prevention purposes.
            </p>
            <br />
            <p>
              {" "}
              <span>Specific Provisions for California Residents </span>
            </p>
            <p>
              This section of the Policy applies to you, if you are a California
              resident. &nbsp;
            </p>
            <p>
              During the last twelve (12) months we have collected the following
              categories of personal information from Users:
            </p>
            <ul>
              <li>
                <p>
                  Information that you chose to upload or otherwise provided by
                  you to PassionStocks, which may include: (i) Identifiers and
                  personal information, such as name, postal addresses, online
                  identifiers, email addresses, passport number or driving
                  license number, social security number;&nbsp;(ii)
                  characteristics of protected classifications, such as gender;
                  facial image; audio, electronic or similar information;
                  professional or employment-related information; and education
                  information.
                </p>
              </li>
              <li>
                <p>
                  Information we collect when you use PassionStocks, including
                  (i) Identifiers and personal information, such as online
                  identifiers, internet protocol (IP) addresses, access device
                  and connection information such as browser type, version, and
                  time zone setting and browser plug-in types and versions; (ii)
                  commercial information, including products or services
                  purchased, obtained, or considered, or other purchasing or
                  consuming histories or tendencies; (iii) Internet or other
                  electronic network activity information, including, but not
                  limited to log-in and log-out time, the duration of sessions,
                  the content uploaded and downloaded, viewed web-pages or
                  specific content on web-pages, activity measures; (iv)
                  Location information, based on your IP address.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Inferences drawn from any of the information identified above
                  to create a profile about you.
                </p>
              </li>
              <li>
                <p>
                  We obtain personal information listed above from third party
                  sources, as detailed in the section titled &ldquo;How Do We
                  Collect Information&rdquo; above.&nbsp;
                </p>
              </li>
            </ul>
            <p>
              We use the personal information that we collect or receive for the
              business purposes as described above under the Section titled
              &ldquo;How Do We Use the Information Collected?&rdquo;.
            </p>
            <p>
              We disclose personal information to third parties for business
              purposes as described above under the Section titled
              &ldquo;Sharing Personal Information with Third Parties&rdquo; in
              the Privacy Policy. In the preceding twelve (12) months, we have
              disclosed all the categories of personal information detailed
              above for business purposes.&nbsp;
            </p>
            <p>
              As previously mentioned in this Policy, we do not sell (as such
              term is defined in the CCPA) personal information and will not
              sell it without providing Users with a right to opt out.&nbsp;
            </p>
            <p>
              You are entitled to the following specific rights under the CCPA
              in relation to personal information related to you:
            </p>
            <ul>
              <li>
                <p>
                  you have a right to request that we will disclose certain
                  information to you about our collection and use of personal
                  information related to you over the past 12 months. After
                  verifying your request, we will disclose to you: (i)
                  The&nbsp;categories of personal information that we collect
                  about you; (ii)The categories of sources from which the
                  personal information is collected; (iii) The purposes for
                  collecting, using, or selling that personal information. (iv)
                  The categories of personal information that we disclosed for a
                  business purpose or sold, and the categories of third parties
                  to whom we disclosed or sold that particular category of
                  personal information. (v) The specific pieces of personal
                  information that we have collected about you. (vi) If we
                  disclosed your personal information for a business purpose,
                  the categories of personal information we have disclosed.
                  (vii) If we sold your personal information, the categories of
                  personal information we have sold.
                </p>
              </li>
              <li>
                <p>
                  You have a right to request that we delete personal
                  information related to you that we collected from you under
                  certain circumstances and exceptions.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  You also have a right not to be discriminated against for
                  exercising your rights under the CCPA.
                </p>
              </li>
            </ul>
            <p>
              To make such requests,&nbsp;
              <span style={{ color: "red", fontWeight: "bold" }}>
                we kindly ask that you would contact our customer service.
              </span>{" "}
              &nbsp;
            </p>
            <p>
              <span style={{ color: "red" }}>
                If you do not have an active PassionStocks account, please
                contact us at info@PassionStocks.com.
              </span>
            </p>
            <p>
              We will verify your request using the information associated with
              your account, including email address. Government identification
              may also be required.&nbsp;
            </p>
            <p>
              A request for access can be made by you only twice within a
              12-months period. Any disclosures that we provide will only cover
              the 12-months period preceding receipt of your request. We do not
              charge a fee to process or respond to your verifiable User request
              unless it is excessive, repetitive, or manifestly unfounded. If we
              determine that the request warrants a fee, we will inform you of
              the reasons for such decision and provide you with a cost estimate
              before processing further your request.
            </p>
            <br />
            <p>
              {" "}
              <span>Updating Personal Information </span>
            </p>
            <p>
              We take steps to ensure that the personal information we collect
              is accurate and up to date, and we provide you with the
              opportunity to update your information through your account
              profile settings. In the event that you believe your information
              is in any way incorrect or inaccurate, please let us know
              immediately.&nbsp;
            </p>
            <p>
              We will make sure we investigate the matter and correct any
              inaccuracies as quickly as possible where necessary or give you
              ways to update it quickly or to delete it - unless we have to keep
              that information for legitimate business or legal purposes.&nbsp;
            </p>
            <p>
              When updating your personal information, we will ask you to verify
              your identity before we can act on your request.
            </p>
            <p>
              If for any reason you have a problem with deleting your personal
              information, please contact PassionStocks&apos;s Customer Support
              and we will make reasonable efforts to delete any such information
              pursuant to any applicable privacy laws.
            </p>
            <p>
              You can review and change your personal information by logging
              into the Site and visiting your account profile page.
            </p>
            <p>
              <span style={{ color: "red" }}>
                {" "}
                If you delete your content or any other details in relation/s
                with you - from the Site, copies of your content or details may
                remain viewable in cached and archived pages, or might have been
                copied or stored by other Site users. Proper access and use of
                information provided on the Site, including any type content ,
                is governed by our Terms of Service.
              </span>
            </p>
            <br />
            <p>
              <span>Contact Us</span>
            </p>
            <p>
              To exercise any of your rights in connection with your personal
              information, we kindly ask that you open a&nbsp; Customer
              Relations ticket . Please include all the relevant details, so
              your ticket can be handled correctly. We will process any requests
              in line with any local laws and our policies and procedures. If
              you do not have an active PassionStocks account,&nbsp;
              <span style={{ color: "red" }}>
                please contact us at&nbsp;
                <a
                  href="mailto:info@passionstocks.com"
                  style={{ color: "red", textDecoration: "underline",fontWeight:'bold' }}
                >
                  info@passionstocks.com
                </a>
                .
              </span>
            </p>
            <p>
              If you have any questions (or comments) concerning this Policy,
              please email our team at&nbsp;
              <a
                href="mailto:info@passionstocks.com"
                style={{ color: "red", textDecoration: "underline" }}
              >
                info@passionstocks.com
              </a>
              , and we will make an effort to reply within a reasonable
              timeframe.
            </p>
            <p>
              For the purposes of GDPR (Article 27), you may contact us at{" "}
              <a
                href="mailto:info@passionstocks.com"
                style={{ color: "red", textDecoration: "underline" }}
              >
                info@passionStocks.com.
              </a>
              &nbsp;
            </p>
            <br />
          </div>
        </div>
      </AnimateKeyframes>
    )
  );
}
