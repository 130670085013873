import store from '../store/store';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const selectLatestVersionSaved = (state) => state.latestVersionSaved;
export default function UpdateVersion() {
  const latestVersionSaved = useSelector(selectLatestVersionSaved);

  const [serverVersion, setserverVersion] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/landing-page') {
      getLatestVersionNumber();
      // console.log('0');
    }
    // console.log(latestVersionSaved);
  }, [pathname]);

  useEffect(() => {
    // console.log(serverVersion);
    if (serverVersion) {
      if (serverVersion.mobileSite.localeCompare(latestVersionSaved.mobileSite) === 1) {
        store.dispatch({
          type: 'app/latestVersionSaved/add',
          payload: serverVersion,
        });
        window.location.reload();
      }
    }
  }, [serverVersion]);

  async function getLatestVersionNumber() {
    var axios = require('axios');

    var config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + '/version',
      headers: {},
    };

    await axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setserverVersion(response.data);
      })
      .catch(function (error) {
        // console.log(error);
        setserverVersion(null);
      });
  }
}
