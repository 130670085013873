import formatNumberToAbbString from '../../../../../../helpers/formatNumberToAbbString';
import unicorn from '../../../../../../images/unicorn.png';

export default function UnicornIndicator_desktop(props) {
  const { totalWorth, unicornRatio } = props;
  return (
    <div className="MyBrandStats__IpoProgressSliderContainer unnamed-character-style-1">
      <div
        style={{
          textAlign: 'right',
          width: '197px',
          color: '#ffbf12',
          fontSize: '18px',
          lineHeight: '18px',
          padding: '0 20px 0 0',
        }}
      >
        ~ ${formatNumberToAbbString(totalWorth?.min)} - ${formatNumberToAbbString(totalWorth?.max)}{' '}
      </div>
      <div style={{ position: 'relative' }}>
        <p
          style={{
            fontSize: '16px',
            lineHeight: '16px',
            position: 'absolute',
            left: '50%',
            top: '-24px',
            transform: 'translate(-50%,0)',
            margin: 0,
            color: '#ffbf12',
          }}
        >
          {' '}
          {Math.ceil(unicornRatio)}%
        </p>{' '}
        <div className="MyBrandStats__IpoProgressSlider">
          <div
            style={{
              position: 'relative',
              background: '#ffbf12',
              padding: '0 1px',
              margin: 0,
              borderRadius: '50px',
              height: unicornRatio > 1.15 ? '15px' : '11px',
              width: `${unicornRatio}%`,
              transition: 'width 0.75s ease-out 0s',
            }}
          />
        </div>
      </div>
      <div style={{ display: 'inline-flex', alignItems: 'center', width: '170px' }}>
        <div
          style={{
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '10px',
          }}
        >
          <img src={unicorn} alt="Unicorn" style={{ width: '50px' }} />
          <p
            style={{
              color: '#fff',
              fontSize: '16px',
              lineHeight: '16px',
              margin: '0',
            }}
          >
            $1B
          </p>
        </div>
      </div>
    </div>
  );
}
