import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Container, IconButton, Input, Typography } from '@mui/material';
import formatNumberToAbbString from '../../../../../../helpers/formatNumberToAbbString';
import {
  adjustWidth,
  convertTextToPositive,
  numberWithCommas,
} from '../../../../../../helpers/inputAdjustment';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '80px',
  },
  slider: {
    color: '#1e1e1e',
    width: '10px',
    height: '400px',
    padding: 0,
    border: '1px solid white',
    borderRadius: '1010px',
    paddingTop: 5,
    marginLeft: 25,
    '&.MuiSlider-vertical': {
      padding: '10px 6px 18px',
    },
    '& .MuiSlider-thumb': {
      marginLeft: '-10px',
      marginBottom: '-12px',
      color: '#ffbf12',
      height: '23px',
      width: '23px',
      '&:hover': {
        boxShadow: '0 0 0 0.2rem rgb(255 191 18 / 44%)', // Adjust halo color as needed
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)', // Adjust halo color as needed
      },
    },
  },
  input: {
    '& .MuiInputBase-input': {
      width: 'fit-content',
      textAlign: 'center',
    },
  },
}));

const Slider_mobile = (props) => {
  const classes = useStyles();
  const [sliderValue, setSliderValue] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [position, setposition] = useState(0);
  const [cursorPosition, setcursorPosition] = useState({ previousValue: 0, position: 0 });
  const widthUnit = 100 / 61;

  const inputRef = useRef(null);
  const handleSliderChange = (event, newValue) => {
    setposition(widthUnit * sliderValue);
    // calc followers:
    let followers;
    if (newValue === 0) {
      followers = 0;
    } else {
      followers =
        Math.pow(10, Math.floor(newValue / 10)) * 1000 * (newValue % 10 === 0 ? 1 : newValue % 10);
    }
    setInputValue(followers);
    adjustWidth(followers, inputRef.current, props.isPhone);
    props.setFollowers(followers);
    let tempSliderValue;
    if (newValue === 0) {
      tempSliderValue = 0;
    } else {
      tempSliderValue = newValue % 10 === 0 ? newValue + 1 : newValue;
    }
    setSliderValue(tempSliderValue);
  };

  const handleInputChange = (event) => {
    let newValue = convertTextToPositive(event.target.value);
    adjustWidth(newValue, inputRef.current, props.isPhone);

    setInputValue(newValue);
    setSliderValue(newValue);
    props.setFollowers(+newValue);
    // console.log(event.target.value);
    // calc the step on slider
    let pow = calculateNumberOfDoublesToRoundDown(newValue);
    let stepDigitUnit = Math.floor(newValue / Math.pow(10, pow));
    let step = (pow - 3) * 10 + stepDigitUnit;
    // console.log(step + ',' + pow + ',' + stepDigitUnit);
    step = step > 62 ? 62 : step;
    step = step < 0 ? 0 : step;
    setSliderValue(step);
    setposition(widthUnit * step);
  };

  function calculateNumberOfDoublesToRoundDown(number) {
    // Calculate the base-2 logarithm of the number
    const log2Number = Math.log2(number);

    // Calculate the base-2 logarithm of 10
    const log2Ten = Math.log2(10);

    // Divide the logarithm of the number by the logarithm of 1000
    const numberOfDoubles = log2Number / log2Ten;

    // Round down to the nearest integer
    const roundedDown = Math.floor(numberOfDoubles);

    return roundedDown;
  }
  let worth = props.calcBrand(inputValue).worth;
  worth = {
    min: `$${formatNumberToAbbString(worth.min)}`,
    max: `$${formatNumberToAbbString(worth.max)}`,
  };
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '30vw',
        padding: '0',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          position: 'relative',
          width: 'fit-content',
          height: '70vw',
          padding: '0 !important',
          margin: '0',
        }}
      >
        <Slider
          className={classes.slider}
          // defaultValue={10}
          orientation="vertical"
          value={sliderValue}
          step={1}
          marks
          min={0}
          max={62}
          track={false}
          onChange={handleSliderChange}
        />
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            left: 0,
            bottom: `${position + 8}%`,
            padding: '0 !important',
            textAlign: 'center !important',
            height: '40px',
            width: props.youtube ? '100px' : '90px',
            margin: '0',
          }}
        >
          <Typography
            className="headline"
            style={{ fontSize: '12px', color: 'white', paddingLeft: '5px' }}
          >
            {props.youtube ? 'subscribers' : 'followers'}
          </Typography>
          <Input
            className={classes.input}
            ref={inputRef}
            sx={{
              border: '1px solid #707070',
              margin: '4px 0',
              padding: '5px',
              height: '23px',
              color: '#ffbf12',
              fontSize: '11px',
              lineHeight: '14px',
              borderRadius: '10px',
              width: '23px',
              textAlign: 'right',
              appearance: 'none',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
            }}
            value={numberWithCommas(inputValue)}
            onChange={handleInputChange}
            onSelectCapture={(e) => {
              if (cursorPosition.previousValue !== e.target.value && e.target.value !== '0') {
                if (e.target.value.length !== cursorPosition.previousValue.length) {
                  e.target.selectionStart =
                    cursorPosition.position +
                    (e.target.value.length - cursorPosition.previousValue.length);
                  e.target.selectionEnd =
                    cursorPosition.position +
                    (e.target.value.length - cursorPosition.previousValue.length);
                } else {
                  e.target.selectionStart = cursorPosition.position - 1;
                  e.target.selectionEnd = cursorPosition.position - 1;
                }
              }
              let temp = { previousValue: e.target.value, position: e.target.selectionStart };
              setcursorPosition(temp);
            }}
            type="text"
          />
        </Container>
      </Container>
      <IconButton style={{ width: '52px', paddingTop: '60px', margin: '0 auto' }}>
        <img src={props.icon} alt="props.alt" style={{ width: props.width }} />
      </IconButton>
      <p
        className="headline"
        style={{
          fontWeight: 'lighter',
          padding: 0,
          margin: 0,
          width: '100%',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: '20px',
        }}
      >
        <span style={{ fontSize: '6px', verticalAlign: 'text-bottom' }}>|</span>
        {worth?.min} - {worth?.max}
      </p>
    </Container>
  );
};

export default Slider_mobile;
