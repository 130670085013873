import "./style.css";
import store from "../../../store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import WinkingEmoji from "../../../images/emoji-winking.png";
import BackBtn from "../../../images/back-page.png";
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function GeneralError(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();
  return (
    <div
      className="GeneralError Page headline"
      style={{ height: windowInnerSize.height, width: "100vw" }}
    >
      <img src={BackBtn} alt="back button" className="back-btn" onClick={()=>{navigate(-1)}}/>
      <div
        className="Page--flex"
        style={{ minHeight: windowInnerSize.height, justifyContent: "center" }}
      >
        <img
          className="GeneralError__WinkingEmoji"
          src={WinkingEmoji}
          alt="Winking emoji"
        />
        <p className="GeneralError__OopsTxt">
          Oops, an error occurred.
          <br />
          Please try again later.
        </p>
      </div>
    </div>
  );
}
