import PSIcon from '../../../../../../images/ps-icon.png';
import InstagramIcon from '../../../../../../images/instagram-icon.svg';
import Unicorn from '../../../../../../images/unicorn.png';
import Nasdaq from '../../../../../../images/nasdaq.svg';
import LocationStation from '../../../../../../images/location.svg';
import DoubleArrow from '../../../../../../images/double-arrow-down-white.png';

import { Box, Button, Container, Dialog, Slide, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { forwardRef, useEffect, useRef, useState } from 'react';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stationName: {
    position: 'absolute',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  thumb: {
    cursor: 'pointer',
    // boxShadow: '0 0 0 20px #ffbf12',
    borderRadius: '100px',
    '&:hover': {
      boxShadow: '0 0 0 7px #ffbf127a', // Adjust halo color as needed
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)', // Adjust halo color as needed
    },
  },
});
export default function Stations__mobile(props) {
  const height = props.height;
  const classes = useStyles();

  const youHerePosition = [-height * 0.22, height * 0.97, height * 2.25, height * 3.41];

  return (
    height && (
      <div style={{ margin: `${height * 1 + 'px'} 20px 0`, display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'relative', paddingRight: height * 0.3 }}>
          {' '}
          <Container
            sx={{
              position: 'relative',
              height: { xs: height * 4 },
              width: { xs: height * 2 },
            }}
          >
            <div
              className={classes.stationName}
              style={{
                opacity: props.index === 0 ? 1 : 0.5,
                transform: 'rotate(30deg)',
                right: height * 0.36,
                bottom: height * 0.5,
              }}
            >
              <img
                src={InstagramIcon}
                alt="Instagram"
                style={{ width: height * 0.23, marginRight: height * 0.07 }}
              />
              <Typography className={classes.textMonarcha} style={{ fontSize: height * 0.125 }}>
                Content creator
              </Typography>
            </div>
            <div
              className={classes.stationName}
              style={{
                opacity: props.index === 1 ? 1 : 0.5,
                alignItems: 'flex-start',
                transform: 'rotate(30deg)',
                right: height * 0.39,
                bottom: height * 1.58,
              }}
            >
              <img
                src={PSIcon}
                alt="PassionStocks"
                style={{ width: height * 0.25, marginRight: '8px' }}
              />
              <Typography
                className={classes.textMonarcha}
                style={{ textAlign: 'center', fontSize: height * 0.12 }}
              >
                Passionstocks <br /> account
              </Typography>
            </div>
            <div
              className={classes.stationName}
              style={{
                opacity: props.index === 2 ? 1 : 0.5,
                transform: 'rotate(30deg)',
                right: height * 0.35,
                bottom: height * 2.9,
              }}
            >
              <img
                src={Unicorn}
                alt="Unicorn"
                style={{ width: height * 0.35, marginRight: '8px' }}
              />
              <Typography className={classes.textMonarcha} style={{ fontSize: height * 0.12 }}>
                $1B personal
                <br /> brand value
              </Typography>
            </div>
            <div
              className={classes.stationName}
              style={{
                opacity: props.index === 3 ? 1 : 0.5,
                transform: 'rotate(0deg) !important',
                right: height * 0.37,
                bottom: height * 3.9,
              }}
            >
              <img src={Nasdaq} alt="Nasdaq" style={{ width: height * 0.94 }} />
            </div>

            <Box
              sx={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                width: 'fit-content',
                height: 'fit-content',
              }}
            >
              <div
                style={{
                  border: '1px solid #d2d2d2',
                  width: height * 0.185,
                  height: height * 3.85,
                  borderRadius: '100px',
                  margin: `0 ${height * 0.11 + 'px'}`,
                }}
              />
              <img
                src={DoubleArrow}
                alt="Direction arrow"
                style={{
                  position: 'absolute',
                  left: height * 0.173,
                  bottom: props.index * height * 1.26 + height * 0.58,
                  transform: 'rotate(180deg)',
                  width: props.index !== 3 ? height * 0.075 : 0,
                }}
              />
              <svg
                className={classes.thumb}
                width="65%"
                height="7%"
                style={{
                  boxShadow: props.index === 0 ? '0 0 0 7px #ffbf127a' : '',
                  position: 'absolute',
                  right: height * 0.064,
                  top: '95%',
                }}
                onClick={() => props.setIndex(0)}
              >
                <circle
                  cx="50%"
                  cy="50%"
                  r="30%"
                  stroke={props.index === 0 ? '#ffbf12' : '#4d4949'}
                  strokeWidth="25"
                />
              </svg>
              <svg
                className={classes.thumb}
                width="65%"
                height="7%"
                style={{
                  boxShadow: props.index === 1 ? '0 0 0 7px #ffbf127a' : '',
                  position: 'absolute',
                  right: height * 0.064,
                  top: '63.2%',
                }}
                onClick={() => props.setIndex(1)}
              >
                <circle
                  cx="50%"
                  cy="50%"
                  r="30%"
                  stroke={props.index === 1 ? '#ffbf12' : '#4d4949'}
                  strokeWidth="25"
                />
              </svg>
              <svg
                className={classes.thumb}
                width="65%"
                height="7%"
                style={{
                  boxShadow: props.index === 2 ? '0 0 0 7px #ffbf127a' : '',
                  position: 'absolute',
                  right: height * 0.064,
                  top: '30.2%',
                }}
                onClick={() => props.setIndex(2)}
              >
                <circle
                  cx="50%"
                  cy="50%"
                  r="30%"
                  stroke={props.index === 2 ? '#ffbf12' : '#4d4949'}
                  strokeWidth="25"
                />
              </svg>
              <svg
                className={classes.thumb}
                width="65%"
                height="7%"
                style={{
                  boxShadow: props.index === 3 ? '0 0 0 7px #ffbf127a' : '',
                  position: 'absolute',
                  right: height * 0.064,
                  top: '-1%',
                }}
                onClick={() => props.setIndex(3)}
              >
                <circle
                  cx="50%"
                  cy="50%"
                  r="30%"
                  stroke={props.index === 3 ? '#ffbf12' : '#4d4949'}
                  strokeWidth="25"
                />
              </svg>
            </Box>
            <div
              style={{
                position: 'absolute',
                right: -height * 0.41,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bottom: youHerePosition[props.index],
                transition: 'all 0.3s linear',
              }}
            >
              <img
                src={LocationStation}
                alt="You here"
                style={{ width: height * 0.28, paddingBottom: '3px' }}
              />
              <Typography
                className={classes.textMonarcha}
                style={{ textAlign: 'center', fontSize: height * 0.0915, lineHeight: 1.2 }}
              >
                Your’e <br /> here
              </Typography>
            </div>
          </Container>
        </div>
        <Container
          sx={{
            width: height * 2.35,
            position: 'relative',
            height: height * 3.85,
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'space-evenly',
          }}
        >
          {props.description[props.index]}
          {props.index !== 0 && (
            <Button
              className={classes.textOpenSans}
              sx={{
                //   position: 'absolute',
                //   left: '50%',
                //   bottom: 0,
                //   transform: 'translate(-50%,0)',
                textTransform: 'none',
                color: '#fff',
                fontSize: '10px',
                lineHeight: 1.3,
                padding: '2px 4px',
                borderColor: 'white',
                borderRadius: '10px',
                marginTop:'12px'
              }}
              variant="outlined"
              onClick={props.handleClickOpenDialog}
            >
              Busy Creator?
              <br /> Get an Account Manager{' '}
            </Button>
          )}
        </Container>
      </div>
    )
  );
}
